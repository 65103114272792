import React, { useEffect, useState } from "react";
import CustomProfileLayout from "../CustomProfileLayout/CustomProfileLayout";
import { Button, Col, Result, Row } from "antd";
import Styles from "./Addresses.module.css";
import { AddressBox } from "./AddressBox";
import { _GET, getRole } from "../../../common/commonFunc";
import _ from "lodash";
import { useHistory } from "react-router";
import LocationDrawer from "./LocationDrawer";
import AddLocationDrawer from "../../../common/AddLocationDrawer/AddLocationDrawer";
const ProfileAddresses = () => {
  const userRole = getRole();
  const history = useHistory();

  const [isProvider, setIsProvider] = useState(
    userRole === "supplier" || userRole === "freelancer"
  );
  const [addressSelDetails, setAddressSelDetails] = useState<any>(false);

  const [addressMainData, setAddressMainData] = useState([]);

  const [addedLocations, setAddedLocations] = useState([]);

  const [coords, setCoords] = useState<any>(null);

  const [open, setOpen] = useState(false);

  const openLocationDrawer = () => {
    setOpen(true);
    setAddressSelDetails(false);
  };

  const closeLocationDrawer = () => {
    setOpen(false);
    onPageLoadHandler();
  };
  const onPageLoadHandler = async () => {
    const userData = JSON.parse(localStorage.getItem("userInfo") || "");
    const getData: any = await _GET(
      "userProfile/getUserAllAddress/" + userData.id,
      true
    );
    console.log("Val of getData is", getData);
    if (_.get(getData, "data[0].user_addresses", [])) {
      setAddressMainData(_.get(getData, "data[0].user_addresses", []));
    } else {
      setAddressMainData([]);
    }
  };

  React.useEffect(() => {
    onPageLoadHandler();
  }, []);

  const getCurrentLocation = () => {
    navigator.geolocation.getCurrentPosition(
      ({ coords: { latitude, longitude } }) => {
        setCoords({ lat: latitude, lng: longitude });
      }
    );
  };

  useEffect(() => {
    if (isProvider) getCurrentLocation();
  }, []);
  return (
    <>
      <CustomProfileLayout>
        <Col span={24}>
          <Row>
            <Col span={18}>
              <Row>
                <h1>Addresses</h1>
              </Row>
            </Col>
            <Col span={6}>
              <Row>
                <button
                  style={{ cursor: "pointer" }}
                  onClick={openLocationDrawer}
                  className={Styles.addButton}
                >
                  <span> +</span> <span>Add Address</span>
                </button>
              </Row>
            </Col>
          </Row>
          <Row gutter={2}>
            {addressMainData && addressMainData.length > 0 ? (
              addressMainData.map((items: any) => {
                return (
                  <Col span={12} style={{ padding: "10px" }}>
                    <AddressBox
                      onPageLoadHandler={onPageLoadHandler}
                      setOpen={setOpen}
                      onEditAddress={setAddressSelDetails}
                      home={items.address_name}
                      addressAllDetails={items}
                      addressLine={items.address}
                      is_default={items.isDefault}
                    />
                  </Col>
                );
              })
            ) : (
              <Result
                title="Address data not found !!"
                extra={
                  <Button
                    type="primary"
                    key="console"
                    onClick={() => {
                      history.goBack();
                    }}
                  >
                    Back to Profile
                  </Button>
                }
              />
            )}
          </Row>
        </Col>
        <AddLocationDrawer
          openLocDrawer={open}
          closeToAddLoc={closeLocationDrawer}
          addressSelDetails={addressSelDetails}
          setAddedLocations={setAddedLocations}
          isAddAddressModalVisible={true}
          coords={coords}
          setCoords={setCoords}
        />
        {/* <LocationDrawer
          openLocDrawer={open}
          closeToAddLoc={closeLocationDrawer}
          addressSelDetails={addressSelDetails}
          setAddedLocations={setAddedLocations}
          isAddAddressModalVisible={true}
          coords={coords}
          setCoords={setCoords}
        /> */}
      </CustomProfileLayout>
    </>
  );
};

export default ProfileAddresses;
