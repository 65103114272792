import React, { useState, useEffect } from "react";
import Styles from "./productDetails.module.css";
import {
  Row,
  Col,
  Button,
  notification,
  Drawer,
  Divider,
  Form,
  Select,
  Tag,
  Breadcrumb,
} from "antd";
import { getCapitalize } from "../../../utils/helpers";
import { useHistory, useLocation, useParams } from "react-router";
import DashboardNavBar from "../../../common/DashboardNavBar/DashboardNavBar";
import savedItemImage from "../../../assests/savedItemImage.png";
import { _GET, _DELETE, getRole, _POST } from "../../../common/commonFunc";
import Share from "../../../assests/customIcons/Share";
import Save from "../../../assests/customIcons/Save";
import {
  API_BASE_URL,
  APPLICATION_ROUTES,
} from "../../../constant/ApplicationRoutes";
import "react-day-picker/dist/style.css";
import { RWebShare } from "react-web-share";
import _ from "lodash";
import MobileTopDashboardNavBar from "../../../common/DashboardNavBar/mobileTopBar";
import axios from "axios";
import LoginPopup from "../../Login/LoginPopup";
import PlusIcon from "../../../assests/PlusIcon.svg";
import MinusIcon from "../../../assests/MinusIcon.svg";
import ServiceProviderCard from "../ServiceDetails/SupplierCard/SupplierCard";
import ReviewCard from "../../Events/ServiceDetails/ReviewCard";
import LoginPopUp from "../../Login/LoginPopup";
import GlobalCarousel from "../../../components/Global/Carousel/Carousel";
import { Typography } from "@mui/material";
import {
  fetchCartItems,
  resetCartFetched,
} from "../../../redux/actions/cartActions";
import breadcrumbIcon from "../../../assests/breadCrumbSeprator.svg";
import { useDispatch } from "react-redux";
// import ServiceProviderCard from "./SupplierCard/SupplierCard";

const { Option } = Select;

const ProductDetails: React.FC = () => {
  const history = useHistory();
  const { serviceId } = useParams<{ serviceId: string }>();

  // const userRole = getRole();
  const [userRole, setUserrole] = useState(getRole());

  const [form] = Form.useForm();
  const [service, setService] = useState<any>([]);
  const [serviceData, setServiceData] = useState<any>([]);
  const [isLoginPopupOpen, setIsLoginPopupOpen] = useState(false);
  const [size, setSize] = useState<any>("");
  const [itemColor, setItemColor] = useState<any>("");
  const [galleryImages, setGalleryImages] = useState([]);
  const [categoriesData, setCategoriesData] = useState<any>([]);
  const [showFullDescription, setShowFullDescription] = useState(false);
  const [cartItems, setCartItems] = useState<any>([]);
  const [savedItem, setSavedItem] = useState(false);
  const [reviewsData, setReviewsData] = useState<any>([]);
  const [displayAllCards, setDisplayAllCards] = useState(false);
  const [count, setCount] = useState(1);
  const [selectedTags, setSelectedTags] = React.useState<any>(null);
  const [isOutOfStock, setIsOutOfStock] = useState(false);
  const dispatch = useDispatch<any>();

  const handleChange = (index: string) => {
    setSelectedTags(index);
    setCount(1);
  };

  const location = useLocation<any>();
  const [selectedColor, setSelectedColor] = useState<any>(false);
  const [selectedSize, setSelectedSize] = useState<any>(false);
  const handleOpenLoginPopup = () => setIsLoginPopupOpen(true);
  const handleCloseLoginPopup = () => setIsLoginPopupOpen(false);
  // const isBuyNowDisabled = !selectedColor ;
  const isAddCartDisabled = !selectedColor;
  const getReviewsDataHandler = async () => {
    try {
      const response = await _GET(
        `review/users/feedbacks?entity_type=product&version=1&entity_id=${serviceId}`,
        true,
        false
      );
      console.log("response", response);
      setReviewsData(_.get(response, "data", []));
    } catch (error) {
      console.log("🚀 ~ getReviewsDataHandler ~ error:", error);
    }
  };

  useEffect(() => {
    getReviewsDataHandler();
  }, [serviceId]);

  useEffect(() => {
    if (serviceData.inventory) {
      const thisInventory = serviceData.inventory.filter(
        (item: any) => item.size === size && item.color === itemColor
      );
      if (thisInventory.available_quantity == 0) {
        setIsOutOfStock(true);
      }
    }
  }, [size, itemColor]);

  const decrementCount = () => {
    if (count > 1) {
      setCount((prevCount) => prevCount - 1);
    }
  };

  const incrementCount = () => {
    var CurrentInventory = serviceData.inventory.filter(
      (item: any) =>
        (item.size === size || item.size === "NA" || item.size === "N-A") &&
        (item.color === itemColor || item.color === null)
    );
    console.log("INVENTORY IAM GETTING IS", CurrentInventory);

    if (
      count < CurrentInventory[0]?.available_quantity &&
      CurrentInventory[0]?.available_quantity > 0
    ) {
      setCount((prevCount) => prevCount + 1);
    } else {
      notification.warning({
        message: "Stock Limit Reached",
        description: "You cannot add more items than available stock.",
      });
    }
  };

  const currentPageUrl = window.location.href;

  const fetchProductData = async () => {
    try {
      const response = await axios.get(
        `${API_BASE_URL}supplier/assignedProducts/${serviceId}`,
        {
          headers: {
            "x-access-token": localStorage.getItem("jwtToken"),
          },
        }
      );

      if (response.data && response.data.data) {
        const fetchedProduct = response.data.data[0];
        setCategoriesData(response.data.data[0].prod_category);
        setService(fetchedProduct);
        setServiceData(fetchedProduct);
      } else {
        console.error("Service data not found in response");
      }
    } catch (error) {
      console.error("Error fetching service data:", error);
    }
  };

  useEffect(() => {
    fetchProductData();
  }, [serviceId]);

  const userdata = localStorage.getItem("userInfo");
  let userId: any;
  let uname: any;
  if (userdata) {
    const userInfo = JSON.parse(userdata);
    userId = userInfo.id;
    uname = userInfo.first_name;
  }

  function stripHtml(html: string) {
    const tempDiv = document.createElement("div");
    tempDiv.innerHTML = html;
    return tempDiv.textContent || tempDiv.innerText || "";
  }

  const plainTextDesc = stripHtml(service?.service_desc);
  const shareText = `Checkout New Service:\n Service Name: ${
    service?.service_name
  }\n ${plainTextDesc.slice(0, 100)}\n Link:\n`;

  const generateImagesForGallery = () => {
    const serviceImages: any = [];
    service?.product_images?.forEach((imageUrl: string) => {
      serviceImages.push({
        original: imageUrl,
        thumbnail: imageUrl,
        originalHeight: 1000, // Adjust the height as needed
        originalWidth: 800,
      });
    });
    setGalleryImages(serviceImages);
  };

  useEffect(() => {
    generateImagesForGallery();

    if (service?.inventory?.length > 0) {
      const firstInventory = service.inventory[0];

      setItemColor(firstInventory.color);
      setSelectedColor(true);

      setSize(firstInventory.size);
      setSelectedTags(0);
      setSelectedSize(true);
      if (firstInventory.available_quantity == 0) {
        setIsOutOfStock(true);
      }
    }
  }, [service]);

  const id = serviceData.id;

  const handleProductClick = async () => {
    // if (isBuyNowDisabled === false) {
    console.log(
      "data for selected color ",
      form.getFieldValue("selectedColor")
    );
    const mainData = _.get(service, "inventory", []);
    const selectedData = _.filter(mainData, (item) => item.color === itemColor);
    console.log("selectedData", selectedData);

    console.log("ID IS ID IS", selectedData, itemColor);
    history.push(
      `${APPLICATION_ROUTES.PRODUCT_BOOKING}/${service.assigned_product_id}`,
      {
        service: service,
        quantity: count,
        color: itemColor,
        colorCode: selectedData[0]?.colorCode ?? null,
        size: size ?? "NA",
        productData: serviceData,
      }
    );
    return;
    // } else {
    //   notification.error({
    //     message: "Size or Color not Selected",
    //     description: "Please select Size/Colour before booking.",
    //     placement: "topRight",
    //   });
    // }
  };

  const handleColorChange = (value: any) => {
    setItemColor(value);
    const filteredData = service.inventory.filter(
      (item: any) => item.color === value
    );
    console.log("filteredData is : ", filteredData);
    if (
      filteredData.length > 0 &&
      filteredData[0].inventory_images?.length > 0
    ) {
      const updatedGalleryImages = filteredData[0].inventory_images.map(
        (imageUrl: string) => ({
          original: imageUrl,
          thumbnail: imageUrl,
        })
      );
      setGalleryImages(updatedGalleryImages);
    } else {
      const mainImages = service.product_images.map((imageUrl: string) => ({
        original: imageUrl,
        thumbnail: imageUrl,
      }));
      setGalleryImages(mainImages);
    }

    setSelectedTags(null);
    if (filteredData[0].available_quantity == 0) {
      setIsOutOfStock(true);
    }
    form.setFieldValue("selectedColor", value);
    form.setFieldValue("selectedSize", selectedTags);
  };

  const handleSizeChange = (size: string, index: string) => {
    console.log("size and index is : ", size, index);
    setSize(size);
    setSelectedSize(true);
    setSelectedTags(index);
    setCount(1);
  };

  const handleAddToCart = async () => {
    // if (isAddCartDisabled === false) {
    let inventory = serviceData.inventory.filter(
      (item: any) => item.size === size && item.color === itemColor
    );

    if (inventory && inventory.length === 0) {
      inventory.push(serviceData.inventory[0]);
    }

    console.log("inventory is : ", inventory);

    const payload = {
      itemDetails: {
        productItemId: serviceData.assigned_product_id,
        itemId: `${serviceData.assigned_product_id}-${inventory[0]?.inventory_id}`,
        itemCategory: serviceData.product_category,
        itemPrice:
          serviceData.discountPercentage === 0
            ? serviceData.product_unit_price
            : serviceData.product_discount_amount,
        itemName: serviceData.product_name,
        itemPic: serviceData.product_images,
        quantity: count,
        itemType: "product",
        supplierId: serviceData?.supplier_info.supplier_id,
        inventory: inventory,
        freelancerId: serviceData.freelancer_info.id,
      },
    };
    try {
      const response = await _POST(`cart/addItem/${userId}`, payload, true);
      if (response && response.status === false) {
        notification.open({
          type: "error",
          message: `${response.message}`,
          description: _.get(response, "response.message", ""),
        });
      } else {
        dispatch(resetCartFetched());

        dispatch(fetchCartItems());
        setCartItems((prevItems: any) => [...prevItems, payload.itemDetails]);
        notification.success({
          message: "Added to Cart Successful",
          description: "You have successfully Added to Cart.",
        });
      }
    } catch (error) {
      console.error(error);
    }
    // } else {
    //   notification.error({
    //     message: "Size or Color not Selected",
    //     description: "Please select Size/Colour before adding to cart.",
    //     placement: "topRight",
    //   });
    // }
  };

  const fetchCartItem = async () => {
    if (userRole !== "guest") {
      try {
        const res = await _GET(`cart/getItem/${userId}`, true);

        if (res.status) {
          const itemsArray: any[] = Object.values(res.data)
            .map((item: any) => JSON.parse(item))
            .flat();

          const serviceItems: any = itemsArray.filter(
            (item) => item.itemType === "service"
          );
          setCartItems(serviceItems);
        }
      } catch (error) {
        console.error(error);
      }
    }
  };

  function getPlainText(text: any) {
    const parser = new DOMParser();
    const doc = parser.parseFromString(text, "text/html");
    return doc.body.textContent || "";
  }

  useEffect(() => {
    fetchCartItem();
    const userrole: any = getRole();
    setUserrole(userrole);
  }, []);

  useEffect(() => {
    console.log("DUUMY DATA VALUES ARE");
    console.log("Values", form.getFieldsValue());
  }, [form]);

  const isItemInCart = (serviceId: any, size: string, itemColor: string) => {
    console.log("color and size is : ", itemColor, size);
    const invId = serviceData?.inventory?.filter((item: any) => {
      if (size === "NA" && itemColor === null) {
        return item.size === "NA" && item.color === null;
      } else if (itemColor === null && size !== "NA") {
        return item.size === size && item.color === null;
      } else {
        return item.size === size && item.color === itemColor;
      }
    });
    console.log("invId is : ", invId ?? "");
    let id = "";
    if (invId && invId.length > 0) {
      id = `${serviceId}-${invId[0]?.inventory_id}`;
    }
    console.log("Add to cart and id is : ", cartItems, id);
    return cartItems.some((item: { itemId: any }) => item.itemId === id);
  };

  const [sameProvider, setSameProvider] = useState<any>(false);

  const checkProvider = () => {
    serviceData.provider_id === userId
      ? setSameProvider(true)
      : setSameProvider(false);
  };

  useEffect(() => {
    checkProvider();
  }, [serviceData]);

  const handleSavedItems = async () => {
    const payload = {
      itemType: "product",
      itemId: service.product_id,
    };

    try {
      const postData = await _POST("userProfile/saveItem", payload, true);
      setSavedItem(true);
    } catch (error) {
      console.error(error);
    }
  };
  const toggleDisplayAll = () => {
    setDisplayAllCards(!displayAllCards);
  };
  const visibleReviewsData = displayAllCards
    ? reviewsData
    : reviewsData.slice(0, 1);

  const handleBreadcrumbClick = (route: any) => {
    history.push(route);
  };

  return (
    <>
      <LoginPopup open={isLoginPopupOpen} onClose={handleCloseLoginPopup} />
      <MobileTopDashboardNavBar />
      <div className={`customLayoutSideContainer ${Styles.servicesContainer}`}>
        <DashboardNavBar />

        <div className={Styles.serviceMainContainer}>
          <Breadcrumb
            style={{ margin: "16px 0" }}
            separator={<img src={breadcrumbIcon} alt=">" />}
            className={Styles.breadcrumbContainer}
          >
            <Breadcrumb.Item
              className="breadcrumbItem"
              onClick={() => handleBreadcrumbClick("/store")}
            >
              Store
            </Breadcrumb.Item>
            <Breadcrumb.Item className="breadcrumbItem">
              Product Details
            </Breadcrumb.Item>
          </Breadcrumb>
          <Row gutter={36}>
            <Col lg={12}>
              <div style={{ height: "300px", position: "relative" }}>
                <GlobalCarousel images={galleryImages} />
              </div>
            </Col>
            <Col xs={18} md={20} lg={10}>
              <div className={Styles.serviceInfo}>
                {/* <DayPicker /> */}
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "flex-start",
                    gap: "10px",
                  }}
                >
                  {service?.product_unit_price && (
                    <>
                      <p style={{ fontSize: "18px", color: "#666" }}>BHD </p>
                      <div className={Styles.productPriceStyle}>
                        {service?.discountPercentage === 0 ||
                        service.discounted_amount === 0 ? (
                          <div className={Styles.productPriceDiv}>
                            {service?.product_unit_price}
                          </div>
                        ) : (
                          <div
                            style={{ display: "flex", flexDirection: "row" }}
                          >
                            <div className={Styles.productPriceDiv}>
                              {service?.product_discount_amount}
                            </div>
                            <span style={{ textDecoration: "line-through" }}>
                              /
                              {typeof service?.product_unit_price === "number"
                                ? service?.product_unit_price.toFixed(2)
                                : service?.product_unit_price}
                            </span>
                          </div>
                        )}
                      </div>
                    </>
                  )}
                  {service?.product_category && (
                    <Button
                      className="elevatedElement"
                      style={{ borderRadius: "25px" }}
                    >
                      <b>{service?.product_category}</b>
                    </Button>
                  )}
                </div>
                {service?.product_name && (
                  <h2>{getCapitalize(service?.product_name)}</h2>
                )}
                {service?.product_description && (
                  <div style={{ width: "100%" }}>
                    {/* <div
                      dangerouslySetInnerHTML={{
                        __html: showFullDescription
                          ? service?.product_description
                          : `${service?.product_description.slice(0, 500)}...`,
                      }}
                      className={`richDesc ${Styles.serviceDescription}`}
                      style={{
                        width: "100%",
                      }}
                    ></div> */}
                    <h3 style={{ marginTop: "20px" }}>Product Description</h3>
                    {showFullDescription ? (
                      <Typography variant="caption">
                        {getPlainText(service?.product_description)}
                      </Typography>
                    ) : (
                      <Typography variant="caption">
                        {getPlainText(
                          `${service?.product_description.slice(0, 500)}...`
                        )}
                      </Typography>
                    )}
                    {!showFullDescription && (
                      <p
                        className="readMore"
                        onClick={() => setShowFullDescription(true)}
                      >
                        Continue Reading
                      </p>
                    )}
                    <h3 style={{ marginTop: "20px" }}>
                      Product Specifications
                    </h3>
                    <Typography variant="caption">
                      {getPlainText(service?.product_other_specs)}
                    </Typography>
                  </div>
                )}

                <Drawer
                  title="Full Description"
                  placement="right"
                  closable={true}
                  onClose={() => setShowFullDescription(false)}
                  open={showFullDescription}
                  width={400}
                >
                  <div
                    dangerouslySetInnerHTML={{
                      __html: service?.product_description,
                    }}
                    className={Styles.serviceDescription}
                  ></div>
                </Drawer>
                <div className={Styles.varientConatiner}>
                  <Form
                    form={form}
                    initialValues={{
                      selectedColor: "default",
                      selectedSize: "default",
                    }}
                    style={{ margin: "0" }}
                  >
                    <div className={Styles.colorSize}>
                      {service?.inventory?.some((item: any) => item.color) && (
                        <div className={Styles.colorSizeInner}>
                          <p className={Styles.label}>
                            <b style={{ color: "gray" }}>Color</b>
                          </p>
                          <Form.Item name="selectedColor" label="">
                            <div className={Styles.selectInput}>
                              <Select
                                placeholder="Select a color"
                                className={Styles.select}
                                value={itemColor}
                                onChange={handleColorChange}
                              >
                                {_.map(
                                  service.inventory,
                                  (color) =>
                                    color.color && (
                                      <Option
                                        key={color.color}
                                        value={color.color}
                                        className={Styles.option}
                                      >
                                        <span
                                          style={{
                                            display: "inline-block",
                                            width: "20px",
                                            height: "20px",
                                            backgroundColor:
                                              color.colorCode ?? "#fff",
                                            marginRight: "10px",
                                            borderRadius: "50%",
                                            border: "1px solid #888",
                                          }}
                                        />
                                        {color.color}
                                      </Option>
                                    )
                                )}
                              </Select>
                            </div>
                          </Form.Item>
                        </div>
                      )}

                      {service?.inventory?.some(
                        (item: any) => item.size !== "NA"
                      ) &&
                        service?.inventory?.some(
                          (item: any) => item.size !== "N-A"
                        ) && (
                          <div className={Styles.colorSizeInner}>
                            <p className={Styles.label}>
                              <b style={{ color: "#f5f4f9" }}>Size</b>
                            </p>
                            <Form.Item name="selectedSize" label="">
                              <div>
                                {_.map(
                                  service.inventory,
                                  (size, index) =>
                                    size.size &&
                                    (size.size !== "NA" ||
                                      size.size !== "N-A") && (
                                      <Tag.CheckableTag
                                        style={{
                                          width: "fit-content",
                                          padding: "5px",
                                          height: "30px",
                                          alignItems: "center",
                                          justifyContent: "center",
                                          border: "1px solid black",
                                          borderRadius: "5px",
                                          background:
                                            selectedTags === index
                                              ? "#31263e"
                                              : "#fff",
                                          pointerEvents:
                                            size.color === itemColor
                                              ? "auto"
                                              : "none",
                                          opacity:
                                            size.color === itemColor ? 1 : 0.5,
                                        }}
                                        key={index}
                                        checked={
                                          selectedTags === index ? true : false
                                        }
                                        onChange={() =>
                                          handleSizeChange(size.size, index)
                                        }
                                      >
                                        {size.size}
                                      </Tag.CheckableTag>
                                    )
                                )}
                              </div>
                            </Form.Item>
                          </div>
                        )}
                    </div>
                  </Form>
                  <Typography
                    variant={"body2"}
                    color={"gray"}
                    fontWeight={"bold"}
                    style={{ marginBottom: "5px" }}
                  >
                    Style
                  </Typography>
                  <Typography
                    variant={"body1"}
                    fontWeight={"bold"}
                    style={{ marginBottom: "20px" }}
                  >
                    {serviceData.product_style}
                  </Typography>

                  <Typography
                    variant={"body2"}
                    color={"gray"}
                    fontWeight={"bold"}
                    style={{ marginBottom: "5px" }}
                  >
                    Brand
                  </Typography>
                  <Typography variant={"body1"} fontWeight={"bold"}>
                    {serviceData.product_brand}
                  </Typography>
                </div>
                {isOutOfStock ? (
                  <>
                    <div
                      style={{
                        border: "1px solid #d8d8d8",
                        borderRadius: "10px",
                        alignItems: "center",
                        padding: "5px",
                        justifyContent: "center",
                        width: "100%",
                        display: "flex",
                      }}
                    >
                      <Typography
                        variant="subtitle2"
                        color={"red"}
                        fontWeight={"bold"}
                      >
                        This product is out of stock!
                      </Typography>
                    </div>
                  </>
                ) : (
                  <div className={Styles.buttonContainer}>
                    <div className={Styles.cartCount}>
                      <button
                        onClick={
                          userRole !== "guest"
                            ? decrementCount
                            : handleOpenLoginPopup
                        }
                        className={Styles.CartButton}
                      >
                        <img src={MinusIcon} alt="Decrease" />
                      </button>
                      <span className={Styles.countDisplay}>
                        {String(count).padStart(2, "0")}
                      </span>
                      <button
                        onClick={
                          userRole !== "guest"
                            ? incrementCount
                            : handleOpenLoginPopup
                        }
                        className={Styles.CartButton2}
                        // disabled = {size === "" && itemColor === ""}
                      >
                        <img src={PlusIcon} alt="Increase" />
                      </button>
                    </div>
                    {isItemInCart(
                      serviceData.assigned_product_id,
                      size,
                      itemColor
                    ) ? (
                      <Button
                        type="primary"
                        className={Styles.addedToCart}
                        disabled
                      >
                        Already Added to Cart
                      </Button>
                    ) : (
                      <Button
                        type="primary"
                        className={Styles.customButton}
                        onClick={
                          userRole !== "guest"
                            ? handleAddToCart
                            : handleOpenLoginPopup
                        }
                      >
                        Add to Cart
                      </Button>
                    )}

                    <Button
                      onClick={
                        userRole !== "guest"
                          ? handleProductClick
                          : handleOpenLoginPopup
                      }
                      // disabled={isBuyNowDisabled}
                    >
                      Buy Now
                    </Button>
                  </div>
                )}

                <div className={Styles.boxInfoContainer}>
                  <div className={Styles.collabration}>
                    {/* <div className={Styles.profileImage}>
                   
                    </div> */}
                    <p>
                      Collobrated with{" "}
                      <span className={Styles.collabBrand}>
                        {_.get(
                          serviceData,
                          "freelancer_info.first_name",
                          "Freelancer"
                        )}{" "}
                        {_.get(serviceData, "freelancer_info.last_name", "")}
                      </span>{" "}
                    </p>
                  </div>
                  <ServiceProviderCard
                    serviceProviderDetails={serviceData?.freelancer_info}
                  />
                </div>
              </div>

              {/* <div className={Styles.othersOfProvider}>
                <h2>{`Provider's Store`}</h2>
                <div className={Styles.otherServices}>
                  {serviceData.map((service: any) => (
                    <ServiceCard
                      key={service?.product_id}
                      service={service}
                      setUpdateList={setUpdateList}
                      similarBy={true}
                      categoriesData={categoriesData}
                    />
                  ))}
                </div>
              </div> */}
              <Divider />
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  justifyItems: "center",
                }}
              >
                <div
                  style={{
                    display: "flex",

                    height: "100%",
                    width: "40%",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <h2>{reviewsData.length} Reviews</h2>
                </div>
                <div
                  onClick={toggleDisplayAll}
                  style={{
                    display: "flex",
                    cursor: "pointer",
                    height: "100%",
                    width: "40%",
                    alignItems: "center",
                    justifyContent: "end",
                    textDecoration: "underline",
                  }}
                >
                  See All
                </div>
              </div>
              <ReviewCard reviews={visibleReviewsData} />
            </Col>
            <Col span={2}>
              <div className={Styles.serviceActions}>
                {/* {isProvider && sameProvider && (
                  <button
                    onClick={confirmDelete}
                    className="elevatedElement iconBtn"
                    style={{
                      position: "relative",
                      width: "40px",
                      height: "40px",
                      cursor: "pointer",
                    }}
                  >
                    <Delete />
                  </button>
                )}
                {isProvider && sameProvider && (
                  <button
                    onClick={openToAdd}
                    className="elevatedElement iconBtn"
                    style={{
                      position: "relative",
                      width: "40px",
                      height: "40px",
                      cursor: "pointer",
                    }}
                  >
                    <Edit />
                  </button>
                )} */}

                {!savedItem ? (
                  <button
                    onClick={(event) => {
                      handleSavedItems();
                      event.preventDefault();
                    }}
                    className="elevatedElement iconBtn"
                    style={{
                      position: "relative",
                      width: "40px",
                      height: "40px",
                      cursor: "pointer",
                    }}
                  >
                    <Save />
                  </button>
                ) : (
                  <img
                    style={{
                      position: "relative",
                      width: "40px",
                      height: "40px",
                      cursor: "pointer",
                    }}
                    src={savedItemImage}
                  ></img>
                )}
                <div>
                  <RWebShare
                    data={{
                      text: shareText,
                      url: currentPageUrl,
                      title: service?.service_name,
                    }}
                  >
                    <button
                      className="elevatedElement iconBtn"
                      style={{
                        position: "relative",
                        width: "40px",
                        height: "40px",
                        cursor: "pointer",
                      }}
                    >
                      <Share />
                    </button>
                  </RWebShare>
                </div>
                <LoginPopUp
                  open={isLoginPopupOpen}
                  onClose={handleCloseLoginPopup}
                />
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
};

export default ProductDetails;
