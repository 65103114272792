import React, { useState, useEffect, useCallback } from "react";
import { Link } from "react-router-dom";
import { Button, notification } from "antd";
import RatingStar from "../../../assests/customIcons/RatingStar";
// import "./ProductCard.css";
import { APPLICATION_ROUTES } from "../../../constant/ApplicationRoutes";
import { getRole, _DELETE, _POST } from "../../commonFunc";
import { useHistory } from "react-router-dom";

import _ from "lodash";
import { useDispatch } from "react-redux";
import Cart from "../../../assests/customIcons/Cart";
import TooltipAddtoCart from "./ToolTip/ToolTipForcart";
import LoginPopUp from "../../../pages/Login/LoginPopup";
import Styles from "./ProductCard.module.css"

const ProductCard = React.memo(
  ({ product, similarBy, categoriesData, onToggle }: any) => {
    console.log("PRODUCT IS IS", product);
    const dispatch = useDispatch<any>();
    const [isActive, setIsActive] = useState<any>(false);
    const [isLoginPopupOpen, setIsLoginPopupOpen] = useState(false);

    const history = useHistory();
    const [count, setCount] = useState(1);
    const [cartItems, setCartItems] = useState<any>([]);
    const [userRole, setUserrole] = useState(getRole());
    const handleTooltipToggle = (isVisible: any) => {
      onToggle(product.id, isVisible);
    };
    const handleOpenLoginPopup = () => setIsLoginPopupOpen(true);
    const handleCloseLoginPopup = () => setIsLoginPopupOpen(false);

    const debouncedCountUpdate = useCallback(
      _.debounce((newCount) => {
        // Code to update count on server or context
      }, 1000),
      []
    );
    useEffect(() => {
      const userrole: any = getRole();
      setUserrole(userrole);
    });

    const handleIncrease = () => {
      if (count < product.inventory[0].quantity) {
        setCount((prev) => {
          const newCount = prev + 1;
          debouncedCountUpdate(newCount);
          return newCount;
        });
      } else {
        notification.warning({
          message: "Stock Limit Reached",
          description: "You cannot add more items than available stock.",
        });
      }
    };

    const handleDecrease = () => {
      setCount((prev) => {
        const newCount = Math.max(prev - 1, 0);
        debouncedCountUpdate(newCount);
        return newCount;
      });
    };

    const handleBuyClick = () => {
      history.push(
        `${APPLICATION_ROUTES.PRODUCT_DETAILS}/${product.assigned_product_id}`,
        {
          productData: product,
          categoriesInfo: categoriesData,
          supplierId: product.supplier_info.supplier_id
        }
      );
    };

    const [galleryImages, setGalleryImages] = useState([]);
    const generateImagesForGallery = () => {
      const serviceImages: any = [];
      product.product_images.forEach((imageUrl: string) => {
        serviceImages.push({
          original: imageUrl,
          thumbnail: imageUrl,
        });
      });
      setGalleryImages(serviceImages);
    };
    useEffect(() => {
      generateImagesForGallery();
    }, []);

    const stripHtml = (html: any) => {
      var temporalDivElement = document.createElement("div");
      temporalDivElement.innerHTML = html;
      return (
        temporalDivElement.textContent || temporalDivElement.innerText || ""
      );
    };

    const truncate = (str: any, n: any) => {
      return str.length > n ? str.substr(0, n - 1) + "..." : str;
    };

    const userdata = localStorage.getItem("userInfo");
    let userId: any;
    let uname: any;
    if (userdata) {
      const userInfo = JSON.parse(userdata);
      userId = userInfo.id;
      uname = userInfo.first_name;
    }
    const handleAddToCart = () => {
      history.push(
        `${APPLICATION_ROUTES.PRODUCT_DETAILS}/${product.assigned_product_id}`,
        {
          service: product,
          categoriesInfo: categoriesData,
        }
      );
    };

    return (
      <div className={Styles.productCard} id={product.assigned_product_id}>
        <Link
          to={{
            pathname: `${APPLICATION_ROUTES.PRODUCT_DETAILS}/${product.assigned_product_id}`,
            state: {
              productData: product,
              categoriesInfo: categoriesData,
            },
          }}
        >
          <div
            style={{
              position: "relative",
              width: "100%",
              height: "300px",
            }}
          >
            {product.product_images && product.product_images.length > 0 && (
              <>
                <img
                  style={{
                    width: "100%",
                    height: "100%",
                    objectFit: "cover",
                    borderRadius: "20px",
                    border: "1px solid #f1f1f1",
                    background: "#f1f1f1",
                  }}
                  src={_.split(product.product_images, ",")[0]}
                />
              </>
            )}

            <div className={Styles.cardRating}>
              <RatingStar />
              <p style={{ fontSize: "14px", color: "#FFF", margin: 0 }}>
                {"3.5"}
              </p>
            </div>
          </div>
        </Link>
        <div className={Styles.serviceInfo}>
          {product.product_name && (
            <p
              className={isActive ? "mainTitle serviceInfo-blur" : "mainTitle"}
            >
              {product.product_name}
            </p>
          )}
          {_.get(product, "product_description", "") && (
            <div
              className={isActive ? "richDesc serviceInfo-blur" : "richDesc"}
            >
              {truncate(
                stripHtml(_.get(product, "product_description", "")),
                30
              )}
            </div>
          )}

          <div
            style={{
              display: "flex",
              alignItems: "left",
              // justifyContent:,
              width: "100%",
            }}
          >
            <div className={Styles.cardPricing}>
              
              <div className={Styles.discountedPricing}>
                {product.discountPercentage > 0 ? (<>
                <div className={Styles.priceDetails}>
              
                <p style={{ fontSize: "18px", margin: 0, color: "#999" }}>BHD</p>
                <p
                  className={Styles.priceVal}
                  style={{ fontSize: "18px", fontWeight: "600", marginLeft:"5px" }}
                >
                  {product.product_discount_amount}
                </p>
                
              </div>
              <div className={Styles.priceDetails}>
              
                <p style={{ fontSize: "18px", margin: 0, color: "#999" }}>/</p>
                <p
                  className={Styles.priceVal}
                  style={{ fontSize: "18px", fontWeight: "600", marginLeft:"5px", color: "#999", textDecoration:"line-through" }}
                >
                  {product.product_unit_price}
                </p>
                
              </div> 
              </>)
              :(
                <div className={Styles.priceDetails}>
              
                <p style={{ fontSize: "18px", margin: 0, color: "#999" }}>BHD</p>
                <p
                  className={Styles.priceVal}
                  style={{ fontSize: "18px", fontWeight: "600", marginLeft:"5px" }}
                >
                  {product.product_unit_price}
                </p>
                
              </div>
              ) }
              </div>
            <div>
              <button
                onClick={() => {
                  if (userRole == "guest") {
                    handleOpenLoginPopup();
                  } else {
                    handleBuyClick();
                  }
                }}
                className={Styles.buyButton}
              >
                Buy
              </button>
            </div>
            </div>
            <LoginPopUp
              open={isLoginPopupOpen}
              onClose={handleCloseLoginPopup}
            />
          </div>
        </div>
      </div>
    );
  }
);

export default ProductCard;
