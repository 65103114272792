import React, { useEffect, useState } from "react";
import { Modal, Form, Input, Button, notification, Select } from "antd";
import CustomDeleteMark from "../../../assests/customDeleteMark.svg";
import styles from "../../OrderPages/components/PopupForm.module.css";
import Cross from "../../../assests/customIcons/Cross";
import { CloseOutlined, InfoCircleOutlined } from "@ant-design/icons";
import { _GET } from "../../../common/commonFunc";
import { Typography } from "@mui/material";

type PopupProps = {
  isVisible: boolean;
  onClose: () => void;
  onSubmit: (values: { reason: string, category: string, categoryId : string }) => void;
  id?: string;
};

const RejectPopUp: React.FC<PopupProps> = ({
  isVisible,
  onClose,
  onSubmit,
  id,
}) => {
  const [form] = Form.useForm();
  const [showPolicyDetails, setShowPolicyDetails] = useState(false);
  const [reasons, setReasons] = useState<any>([]);
  const [selectedReason, setSelectedReason] = useState({
    id: "",
    name: "",
  });


  const submitForm = () => {
    form
      .validateFields()
      .then((values) => {
        const reason  = values.reason;
        const categoryId = selectedReason.id;
        const category = selectedReason.name;
        onSubmit({ reason, categoryId, category });
        form.resetFields();
        onClose();
      })
      .catch((info) => {
        console.log("Validate Failed:", info);
      });
  };
  const togglePolicyDetails = () => {
    setShowPolicyDetails((prev) => !prev);
  };


  const getCancelReasons = async () => {
    try {
      const res = await _GET("supplier/cancel/product/category", true, false);
      if (res.statusCode === 200 && res.status === true) {
        setReasons(res.data);
      } else {
        notification.error({
          message: res.message,
        });
      }
    } catch (error) {
      console.log("error :", error);
    }
  };

  useEffect(() => {
    getCancelReasons();
  }, []);

  const handleReasonChange = (value: any) => {
    const category = reasons.find(
      (cat: any) => cat.category_id === value
    );
    setSelectedReason({
      id: category?.category_id,
      name: category?.category_name,
    });
  };

  return (
    <Modal
      open={isVisible}
      onCancel={onClose}
      footer={null}
      className={styles.modal}
      centered
    >
      <Button
        className={`${styles.closeIcon} elevatedElement iconBtn`}
        onClick={onClose}
      >
        <Cross />
      </Button>
      <img src={CustomDeleteMark} alt="cancel" className={styles.cancelIcon} />
      <h3 className={styles.title}>
        Are you sure you want to cancel the order?
      </h3>
      <Form
        form={form}
        layout="vertical"
        name="reasonForm"
        className={styles.form}
      >
                <Form.Item
          name="cancel_reason"
          rules={[{ required: true, message: "Please select a reason!" }]}
        >
             <Select
                    placeholder="Select a reason"
                    onChange={async (value) => {
                      handleReasonChange(value); 
                    }}
                    options={reasons.map((category: any) => ({
                      label: category.category_name,
                      value: category.category_id,
                    }))}
                    className={styles.formSelect}
                    filterOption={(input: any, option: any) =>
                      (option?.label ?? "")
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                  />
        </Form.Item>
        <Form.Item
          name="reason"
          rules={[{ required: true, message: "Please enter your reason!" }]}
          className={styles.formItem}
        >
          <Input.TextArea
            rows={4}
            placeholder="Give a reason..."
            className={styles.input}
          />
        </Form.Item>
        <div className={styles.footer}>
          <Button
            key="back"
            onClick={togglePolicyDetails}
            className={styles.policyButton}
            icon={<InfoCircleOutlined />}
          >
            <span className={styles.cancelText}>Policy</span>
          </Button>

          <Button
            key="submit"
            type="primary"
            onClick={submitForm}
            className={styles.submitButton}
          >
            Yes, Cancel it
          </Button>
        </div>
        {showPolicyDetails && (
          <div className={styles.policyDetails}>
            <h4 className={styles.policyHeader}>Cancellation Policy</h4>
            <ul className={styles.policyList}>
              <li>
             <Typography variant="caption"> Customer cancels the order before shipping: return product cost + shipping cost</Typography>
              </li>
              <li>
              <Typography variant="caption"> Customer cancels the order after shipping but before receiving: return product cost only</Typography>
              </li>
              <li>
              <Typography variant="caption"> Customer returns the product after receiving (subject to product being in shape/ product being return-able): </Typography></li>
              <Typography variant="caption" ml={2}>    - If defect/order not matching: return product cost + shipping</Typography><br/>
              <Typography variant="caption" ml={2}>   - If customer does not like it: return product cost only </Typography>
             
            </ul>
          </div>
        )}
      </Form>
    </Modal>
  );
};

export default RejectPopUp;
