import React, { useState, useEffect, useRef } from "react";
import { useHistory, useLocation } from "react-router-dom";
import axios from "axios";
import {
  Button,
  Input,
  Tag,
  Card,
  Carousel,
  Select,
  Slider,
  Drawer,
  Pagination,
  Space,
  Result,
} from "antd";
import { LeftOutlined, RightOutlined } from "@ant-design/icons";
import HomeLogo from "../../../assests/logoSmall.svg";
import ServiceLogo from "../../../assests/Services.svg";
import EventsLogo from "../../../assests/Event.svg";
import StoreLogo from "../../../assests/Store.svg";
import ProfileLogo from "../../../assests/profileSmall.svg";
import SearchIcon from "../../../assests/customIcons/Search";
import Styles from "./DashboardNavBar.module.css";
import {
  API_BASE_URL,
  APPLICATION_ROUTES,
} from "../../../constant/ApplicationRoutes";
import { getRole } from "../../commonFunc";
import Cross from "../../../assests/customIcons/Cross";
import {
  ArrowBack,
  ArrowBackIos,
  CancelRounded,
  RestorePageTwoTone,
} from "@mui/icons-material";
import Back from "../../../assests/customIcons/Bacj";

const { Meta } = Card;
const { Option } = Select;

interface SearchResult {
  type: string;
  id: string;
  images: string | null | undefined;
  title: string | null | undefined;
  content: string | null | undefined;
  price?: number | null | undefined;
  rating?: number | null | undefined;
  followers?: number | null | undefined;
}

interface PostData {
  _id: string;
  userId: string;
  imageUrls: string[];
  caption: string;
  type: string;
}

const sanitizeHtml = (htmlContent: string, limit: number) => {
  const text = htmlContent.replace(/<\/?[^>]+(>|$)/g, "");
  return text.length > limit ? text.slice(0, limit) + "..." : text;
};

const getValidImageArray = (images: string | null | undefined) => {
  if (!images || images.toLowerCase() === "null" || images.trim() === "") {
    return [];
  }
  return images
    .replace(/[{}]/g, "")
    .split(",")
    .filter((img) => img.trim());
};

const ServiceCard: React.FC<{ item: SearchResult }> = ({ item }) => {
  const { title, content, price, rating, images, id } = item;
  const imageArray = getValidImageArray(images);
  const navigate = useHistory();

  const handleCardClick = () => {
    navigate.push(`${APPLICATION_ROUTES.SERVICE}/${id}`);
  };

  const carouselSettings = {
    arrows: true,
    nextArrow: <RightOutlined className={Styles.carouselArrow} />,
    prevArrow: <LeftOutlined className={Styles.carouselArrow} />,
  };

  return (
    <Card
      hoverable
      className={Styles.card}
      cover={
        imageArray.length > 0 ? (
          <Carousel {...carouselSettings}>
            {imageArray.map((img, index) => (
              <img
                key={index}
                src={img}
                alt={title || "Service"}
                className={Styles.cardImage}
              />
            ))}
          </Carousel>
        ) : (
          <div className={Styles.placeholder}>No Image</div>
        )
      }
      onClick={handleCardClick}
    >
      <Meta
        title={title}
        description={
          <div
            dangerouslySetInnerHTML={{
              __html: sanitizeHtml(content || "", 10),
            }}
          />
        }
      />
      <p>Price: BHD {price || "0"}</p>
      <p>Rating: {rating || "0"}</p>
    </Card>
  );
};

const EventCard: React.FC<{ item: SearchResult }> = ({ item }) => {
  const { title, content, price, images } = item;
  const imageArray = getValidImageArray(images);
  const navigate = useHistory();
  const handleCardClick = () => {
    navigate.push(`${APPLICATION_ROUTES.EVENT_DETAILS_NEW}/${item.id}`);
  };

  const carouselSettings = {
    arrows: true,
    nextArrow: <RightOutlined className={Styles.carouselArrow} />,
    prevArrow: <LeftOutlined className={Styles.carouselArrow} />,
  };

  return (
    <Card
      hoverable
      className={Styles.card}
      cover={
        imageArray.length > 0 ? (
          <Carousel {...carouselSettings}>
            {imageArray.map((img, index) => (
              <img
                key={index}
                src={img}
                alt={title || "Event"}
                className={Styles.cardImage}
              />
            ))}
          </Carousel>
        ) : (
          <div className={Styles.placeholder}>No Image</div>
        )
      }
      onClick={handleCardClick}
    >
      <Meta
        title={title}
        description={
          <div
            dangerouslySetInnerHTML={{
              __html: sanitizeHtml(content || "", 10),
            }}
          />
        }
      />
      <p>Price: BHD {price || "0"}</p>
    </Card>
  );
};

const PostCard: React.FC<{ post: PostData }> = ({ post }) => {
  return (
    <Card
      hoverable
      className={Styles.card}
      cover={
        post.imageUrls && post.imageUrls.length > 0 ? (
          <img
            src={post.imageUrls[0]}
            alt={post.caption || "Post"}
            className={Styles.cardImage}
          />
        ) : (
          <div className={Styles.placeholder}>No Image</div>
        )
      }
    >
      <Meta title={post.caption} />
    </Card>
  );
};

const ProductCard: React.FC<{ item: SearchResult }> = ({ item }) => {
  const {
    title = "No Title",
    content = "No Description",
    price = "0",
    rating = "0",
    images,
    id = "",
  } = item;
  const imageArray = getValidImageArray(images);
  const navigate = useHistory();

  const handleCardClick = () => {
    navigate.push(`${APPLICATION_ROUTES.PRODUCT_DETAILS}/${id}`);
  };

  const carouselSettings = {
    arrows: true,
    nextArrow: <RightOutlined className={Styles.carouselArrow} />,
    prevArrow: <LeftOutlined className={Styles.carouselArrow} />,
  };

  return (
    <Card
      hoverable
      className={Styles.card}
      cover={
        imageArray.length > 0 ? (
          <Carousel {...carouselSettings}>
            {imageArray.map((img, index) => (
              <img
                key={index}
                src={img}
                alt={title || "Product"}
                className={Styles.cardImage}
              />
            ))}
          </Carousel>
        ) : (
          <div className={Styles.placeholder}>No Image</div>
        )
      }
      onClick={handleCardClick}
    >
      <Meta
        title={title}
        description={
          <div
            dangerouslySetInnerHTML={{
              __html: sanitizeHtml(content || "", 10),
            }}
          />
        }
      />
      <p>Price: BHD {price || "0"}</p>
      <p>Rating: {rating || "0"}</p>
    </Card>
  );
};

const UserCard: React.FC<{ item: SearchResult }> = ({ item }) => {
  const { title, content, images } = item;
  const imageArray = getValidImageArray(images);

  const navigate = useHistory();

  const handleCardClick = () => {
    navigate.push(`${APPLICATION_ROUTES.PROFILE}/${item.id}`);
  };

  const carouselSettings = {
    arrows: true,
    nextArrow: <RightOutlined className={Styles.carouselArrow} />,
    prevArrow: <LeftOutlined className={Styles.carouselArrow} />,
  };

  return (
    <Card
      hoverable
      className={Styles.card}
      cover={
        imageArray.length > 0 ? (
          <Carousel {...carouselSettings}>
            {imageArray.map((img, index) => (
              <img
                key={index}
                src={img}
                alt={title || "User"}
                className={Styles.cardImage}
              />
            ))}
          </Carousel>
        ) : (
          <div className={Styles.placeholder}>
            {title ? title[0].toUpperCase() : "U"}
          </div>
        )
      }
      onClick={handleCardClick}
    >
      <Meta
        title={title}
        description={
          <div
            dangerouslySetInnerHTML={{
              __html: sanitizeHtml(content || "", 10),
            }}
          />
        }
      />
      <p>Followers: {item.followers || 0}</p>
    </Card>
  );
};

const SearchPage: React.FC = () => {
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [results, setResults] = useState<SearchResult[]>([]);
  const [postResults, setPostResults] = useState<PostData[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [activeFilter, setActiveFilter] = useState<string>("services");
  const [userRole, setUserrole] = useState(getRole());
  const [activeNav, setActiveNav] = useState("1");
  const [availableFilters, setAvailableFilters] = useState<string[]>([]);
  const [priceRange, setPriceRange] = useState<number[]>([0, 1000]);
  const [sortOption, setSortOption] = useState<string>("priceAsc");
  const [isSidebarVisible, setSidebarVisible] = useState<boolean>(false);
  const [isMobile, setIsMobile] = useState(false);
  const [showHeader, setShowHeader] = useState(true);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [totalPages, setTotalPages] = useState<number>(0);

  const history = useHistory();
  const location = useLocation();
  const searchInputRef = useRef<HTMLInputElement>(null);

  const getQueryParams = (search: string) => {
    return new URLSearchParams(search);
  };

  useEffect(() => {
    const params = getQueryParams(location.search);
    const searchTerm = params.get("searchTerm");
    const priceMin = params.get("priceMin") || "0";
    const priceMax = params.get("priceMax") || "1000";
    const sort = params.get("sortBy") || "priceAsc";
    const page = params.get("page") || "1";

    if (searchTerm) {
      setSearchQuery(searchTerm);
    }
    setPriceRange([parseInt(priceMin), parseInt(priceMax)]);
    setSortOption(sort);
    setCurrentPage(parseInt(page));

    fetchResults(searchTerm || "", parseInt(page));
  }, [location.search]);

  useEffect(() => {
    searchInputRef.current?.focus();
  }, []);

  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth <= 576);
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const fetchResults = async (query: string, page: number) => {
    setLoading(true);
    try {
      const response = await axios.get(
        `${API_BASE_URL}search/octopus/home?searchTerm=${query}&limit=10&page=${page}`
      );

      const fetchedResults = response.data.data.results;
      setTotalPages(parseInt(response.data.data.totalPages, 10));

      const fetchedPostResults = fetchedResults.filter(
        (item: any) => item.type === "post"
      );
      setPostResults(fetchedPostResults);

      const newFilters: any = Array.from(
        new Set(
          fetchedResults.map((result: any) => result.type).filter(Boolean)
        )
      );
      setAvailableFilters(newFilters);
      setActiveFilter(newFilters[0] || "");

      if (Array.isArray(fetchedResults)) {
        setResults(fetchedResults);
      }
    } catch (error) {
      console.error("Error fetching search results:", error);
    }
    setLoading(false);
  };

  const updateUrlWithFilters = () => {
    const params = new URLSearchParams();
    if (searchQuery) {
      params.set("searchTerm", searchQuery);
    }
    params.set("priceMin", priceRange[0].toString());
    params.set("priceMax", priceRange[1].toString());
    params.set("sortBy", sortOption);
    params.set("page", currentPage.toString());

    history.push(`?${params.toString()}`);
  };

  const handleSearch = () => {
    setResults([]);
    setPostResults([]);
    setCurrentPage(1);
    updateUrlWithFilters();
    fetchResults(searchQuery, 1);
  };

  const handleEnterPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      handleSearch();
    }
  };

  const handleFilterChange = (value: string) => setActiveFilter(value);

  const handlePriceRangeChange = (value: number[]) => setPriceRange(value);

  const handleSortChange = (value: string) => setSortOption(value);

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
    const params = new URLSearchParams(location.search);
    params.set("page", page.toString());
    history.push(`?${params.toString()}`);
    fetchResults(searchQuery, page);
  };

  const filteredResults = results
    .filter((item) => {
      if (!item || typeof item !== "object") return false;
      return item.type === activeFilter;
    })
    .filter((item) => {
      if (item.price != null && !isNaN(item.price)) {
        return (
          item.price >= (priceRange?.[0] ?? 0) &&
          item.price <= (priceRange?.[1] ?? Infinity)
        );
      }
      return true;
    })
    .sort((a, b) => {
      if (sortOption === "priceAsc") {
        return (a.price || 0) - (b.price || 0);
      } else if (sortOption === "priceDesc") {
        return (b.price || 0) - (a.price || 0);
      } else if (sortOption === "ratingDesc") {
        return (b.rating || 0) - (a.rating || 0);
      }
      return 0;
    });

  const handleSidebarOpen = () => setSidebarVisible(true);
  const handleSidebarClose = () => setSidebarVisible(false);

  const renderBottomNav = () => {
    const getActiveStatus = (path: any) => {
      return history.location.pathname === path
        ? "grayscale(0%)"
        : "grayscale(100%)";
    };

    const handleProfileClick = () => {
      if (userRole !== "guest") {
        history.push("/profile");
      } else {
        history.push("/login");
      }
    };

    return (
      <div className={Styles.bottomNav}>
        <div
          className={Styles.navItem}
          onClick={() => {
            setActiveNav("/service");
            history.push("/service");
          }}
          style={{
            filter:
              activeNav === "/service" ? "grayscale(0%)" : "grayscale(100%)",
          }}
        >
          <img src={ServiceLogo} alt="Services" />
          <span>Services</span>
        </div>
        <div
          className={Styles.navItem}
          onClick={() => {
            setActiveNav("/events");
            history.push("/events");
          }}
          style={{
            filter:
              activeNav === "/events" ? "grayscale(0%)" : "grayscale(100%)",
          }}
        >
          <img src={EventsLogo} alt="Events" />
          <span>Events</span>
        </div>
        <div
          className={`${Styles.navItem}`}
          onClick={() => {
            setActiveNav("/");
            history.push("/");
          }}
          style={{
            filter: activeNav === "/" ? "grayscale(0%)" : "grayscale(100%)",
          }}
        >
          <img src={HomeLogo} alt="Home" />
          <span>Home</span>
        </div>
        <div
          className={Styles.navItem}
          onClick={() => {
            setActiveNav("/store");
            history.push("/store");
          }}
          style={{
            filter:
              activeNav === "/store" ? "grayscale(0%)" : "grayscale(100%)",
          }}
        >
          <img src={StoreLogo} alt="Store" />
          <span>Store</span>
        </div>
        <div
          className={Styles.navItem}
          onClick={handleProfileClick}
          style={{
            filter:
              activeNav === "/profile" ? "grayscale(0%)" : "grayscale(100%)",
          }}
        >
          <img
            src={ProfileLogo}
            alt="Profile"
            style={{
              border: "1px solid #999999 !important",
              height: "21px",
              width: "21px",
              borderRadius: "2px",
              backgroundColor: "#999999",
            }}
          />
          <span>Profile</span>
        </div>
      </div>
    );
  };

  return (
    <div className={Styles.pageContainer}>
      <Drawer
        title="Filters"
        placement="right"
        closable={false}
        onClose={handleSidebarClose}
        open={isSidebarVisible}
        width={300}
        extra={
          <Space style={{ zIndex: 100 }}>
            <Button
              onClick={handleSidebarClose}
              className="elevatedElement iconBtn"
            >
              <Cross />
            </Button>
          </Space>
        }
      >
        {activeFilter !== "users" && (
          <>
            <h4>Price Range</h4>
            <Slider
              range
              min={0}
              max={1000}
              value={priceRange}
              onChange={handlePriceRangeChange}
            />
          </>
        )}
        {activeFilter === "users" && (
          <>
            <h4>Followers</h4>
            <Slider
              range
              min={0}
              max={1000}
              onChange={handlePriceRangeChange}
            />
          </>
        )}
        <h4>Sort By</h4>
        <Select
          defaultValue="priceAsc"
          onChange={handleSortChange}
          style={{ width: "100%" }}
        >
          <Option value="priceAsc">Price: Low to High</Option>
          <Option value="priceDesc">Price: High to Low</Option>
          <Option value="ratingDesc">Rating: High to Low</Option>
        </Select>
      </Drawer>

      <div className={Styles.searchPage}>
        <header
          className={`${Styles.header} ${showHeader ? "" : Styles.hideHeader}`}
        >
          <img
            src="https://development.theoctopus.ai/static/media/headerLogoSupplier.0d586ff5ff89d17cac528ad8bcc5bbf0.svg"
            alt="Logo"
            className={Styles.logo}
          />
          <div className={Styles.inputBox}>
            <SearchIcon />

            <input
              placeholder="Search..."
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              onKeyPress={handleEnterPress}
              className={Styles.inputField}
              ref={searchInputRef}
            />

            <Button
              type="primary"
              onClick={handleSidebarOpen}
              loading={loading}
              className={` ${Styles.filterIcon}`}
            >
              <svg
                width="25"
                height="25"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M2.5 2.5V17.5H4.16667V2.5H2.5Z"
                  fill="url(#paint0_linear)"
                />
                <path
                  id="leftHorizontalBar"
                  d="M1.66667 14H6.5V16.5H0.3V14Z"
                  fill="url(#paint0_linear_left)"
                  stroke="white"
                  strokeWidth="0.3"
                />
                <path
                  d="M8.33333 2.5V17.5H10V2.5H8.33333Z"
                  fill="url(#paint0_linear)"
                />
                <path
                  id="centerHorizontalBar"
                  d="M6.5 4.16667H11.8333V6.83333H6.5V5.16667Z"
                  fill="url(#paint0_linear_center)"
                  stroke="white"
                  strokeWidth="0.3"
                />
                <path
                  d="M15.8333 2.5V17.5H17.5V2.5H15.8333Z"
                  fill="url(#paint0_linear)"
                />
                <path
                  id="rightHorizontalBar"
                  d="M14.5 14H20V16.5H13.16667V14Z"
                  fill="url(#paint0_linear_right)"
                  stroke="white"
                  strokeWidth="0.3"
                />
                <defs>
                  <linearGradient
                    id="paint0_linear"
                    x1="3.4523"
                    y1="4.64286"
                    x2="13.9544"
                    y2="17.4788"
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop stopColor="#7027FF" />
                    <stop offset="1" stopColor="#00BCFF" />
                  </linearGradient>
                  <linearGradient
                    id="paint0_linear_left"
                    x1="1.66667"
                    y1="14"
                    x2="5"
                    y2="15.6667"
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop stopColor="#7027FF" />
                    <stop offset="1" stopColor="#00BCFF" />
                  </linearGradient>
                  <linearGradient
                    id="paint0_linear_center"
                    x1="7.5"
                    y1="5.16667"
                    x2="10.8333"
                    y2="6.83333"
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop stopColor="#7027FF" />
                    <stop offset="1" stopColor="#00BCFF" />
                  </linearGradient>
                  <linearGradient
                    id="paint0_linear_right"
                    x1="15"
                    y1="14"
                    x2="18.3333"
                    y2="15.6667"
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop stopColor="#7027FF" />
                    <stop offset="1" stopColor="#00BCFF" />
                  </linearGradient>
                </defs>
              </svg>
            </Button>
            <Button
              className={`${Styles.filterIcon}`}
              onClick={() => {
                setSearchQuery("");
                searchInputRef.current?.focus();
                setActiveFilter("");
                history.replace({ search: "" });
                setResults([]);
                setPostResults([]);
              }}
            >
              <CancelRounded fill="white" />
            </Button>
          </div>
          {!isMobile && (
            <nav className={Styles.navLinks}>
              <a href="/">Home</a>
              <a href="/service">Services</a>
              <a href="/events">Events</a>
              <a href="/store">Store</a>
            </nav>
          )}
        </header>

        <div className={Styles.filterCapsules}>
          <Button
            className={Styles.backButton}
            onClick={() => history.goBack()}
          >
            <ArrowBackIos />
          </Button>

          {availableFilters
            .filter((filter) => filter !== "story")
            .map((filter) => (
              <Tag
                key={filter}
                className={`${Styles.singleCategory} ${
                  activeFilter === filter ? Styles.activeFilter : ""
                }`}
                onClick={() => setActiveFilter(filter)}
              >
                {filter === "users"
                  ? "Octopus"
                  : filter.charAt(0).toUpperCase() + filter.slice(1)}
              </Tag>
            ))}
        </div>

        <div className={Styles.resultsWrapper}>
          <div className={Styles.resultsContainer}>
            {activeFilter === "post" &&
              postResults.map((post) => (
                <PostCard key={post._id} post={post} />
              ))}

            {filteredResults.map((item) => {
              switch (item.type) {
                case "services":
                  return <ServiceCard key={item.id} item={item} />;
                case "users":
                  return <UserCard key={item.id} item={item} />;
                case "events":
                  return <EventCard key={item.id} item={item} />;
                case "product":
                  return <ProductCard key={item.id} item={item} />;
                default:
                  return null;
              }
            })}

            {!filteredResults.length && (
              <Result
                status={"404"}
                title="No results found"
                subTitle="Try different keywords"
              />
            )}
            {!loading && totalPages > 1 && isMobile && (
              <Pagination
                current={currentPage}
                total={totalPages * 10}
                pageSize={10}
                onChange={handlePageChange}
                className={Styles.pagination}
              />
            )}
          </div>
          {!loading && totalPages > 1 && !isMobile && (
            <Pagination
              current={currentPage}
              total={totalPages * 10}
              pageSize={10}
              onChange={handlePageChange}
              className={Styles.pagination}
            />
          )}
        </div>

        {loading && <p>Loading...</p>}
        {isMobile && renderBottomNav()}
      </div>
    </div>
  );
};

export default SearchPage;
