import React, { useEffect, useState } from "react";
import { useParams, useLocation, useHistory } from "react-router-dom";
import axios from "axios";
import { Button, Modal, message } from "antd";
import { CheckCircleOutlined } from "@ant-design/icons";
import styles from "./ServiceConfirmationPage.module.css";
import tickPop from "../../../assests/tickPop.png";
// import CustomLayoutModalTick from "../../assests/customLayoutTick.png";
import FailedTick from "../../../assests/customDeleteMark.svg";
import Confetti from "react-confetti";
import confettiIMG from "../../../assests/confettiIMG.png";

import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
// import bgImg from "../../../assests/bgPayConf.png";
import bgImg from "../../../assests/bgEvent.png";
import { _POST } from "../../../common/commonFunc";
import { API_BASE_URL } from "../../../constant/ApplicationRoutes";

interface RouteParams {
  event_id: string;
  charge_id: string;
}

function EventConfirmationPage() {
  const { event_id, charge_id } = useParams<RouteParams>();
  const { search } = useLocation();
  const queryParams = new URLSearchParams(search);

  const tap_id = queryParams.get("tap_id");
  const data = queryParams.get("data");

  const [paymentData, setPaymentData] = useState<any>(null);
  const [isFailureModalVisible, setIsFailureModalVisible] = useState(false);
  const [failedMessage, setFailedMessage] = useState("");
  const [isSuccessModalVisible, setIsSuccessModalVisible] =
    useState<boolean>(false);

  const showSuccessModal = () => {
    setIsSuccessModalVisible(true);
  };

  // useEffect(() => {
  //   // https://development.theoctopus.ai:8443/payment/event/getPaymentById?chargeId=${tap_id}&eventId=807a5310-d0c4-4983-ae0d-f0b82c7ef1f3
  //   const apiUrl = `https://development.theoctopus.ai:8443/payment/event/getPaymentById?chargeId=${tap_id}&eventId=${event_id}`;

  //   axios
  //     .get(apiUrl)
  //     .then(async ({ data }) => {
  //       if (data.status == true && data.data.dataInserted == true) {
  //         const evPayload = {
  //           event_start_time: data.data.event_start_time,
  //           event_end_time: data.data.event_end_time,
  //           event_id: data.data.event_id,
  //           provider_id: data.data.providerId,
  //         };

  //         const confirmBooking = await _POST(
  //           `services/confirmBookEvent/${event_id}`,
  //           evPayload,
  //           true
  //         );
  //         showSuccessModal();
  //         console.log(
  //           "➡️   ~ file: ServiceConfirmation.tsx:65 ~ .then ~ confirmBooking:",
  //           confirmBooking
  //         );
  //       }
  //     })
  //     .catch((error) => {
  //       // message.error(error.response.data.message);
  //       setFailedMessage(error.response.data.message);
  //       setIsFailureModalVisible(true);
  //       console.error("Error fetching payment data:", error);
  //     });
  // }, [event_id, charge_id]);

  useEffect(() => {
    const apiUrl = `${API_BASE_URL}payment/event/getPaymentById?chargeId=${tap_id}&eventBookingId=${event_id}`;

    axios
      .get(apiUrl, {
        headers: {
          "x-access-token": localStorage.getItem("jwtToken"),
        },
      })
      .then(async ({ data }) => {
        if (data.status === false) {
          setFailedMessage(data.message || "An error occurred");
          setIsFailureModalVisible(true);
        } else if (data.status === true && data.data.dataInserted === true) {
          const evPayload = {
            event_start_time: data.data.event_start_time,
            event_end_time: data.data.event_end_time,
            event_id: data.data.event_id,
            provider_id: data.data.providerId,
          };

          const confirmBooking = await _POST(
            `services/confirmBookEvent/${event_id}`,
            evPayload,
            true
          );
          if (confirmBooking.status === false) {
            setFailedMessage(confirmBooking.message || "An error occurred");
            setIsFailureModalVisible(true);
          } else {
            showSuccessModal();
          }
          // showSuccessModal();
        }
      })
      .catch((error) => {
        setFailedMessage(
          error.response?.data.message || "An unexpected error occurred"
        );
        setIsFailureModalVisible(true);
        console.error("Error fetching payment data:", error);
      });
  }, [event_id, charge_id, tap_id]);

  const history = useHistory();

  const handleSuccessModalClose = () => {
    setIsSuccessModalVisible(false);
    history.push("/events");
  };

  const handleFailureModalClose = () => {
    setIsFailureModalVisible(false);
    history.push("/events");
  };

  // console.log(paymentData.data);

  return (
    <div
      style={{
        padding: "24px",
        borderRadius: "1px",
        backgroundImage: `url(${bgImg})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        height: "100vh",
        width: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        // border: "1px solid red",
      }}
    >
      {failedMessage == "" && (
        <Dialog
          open={isSuccessModalVisible}
          onClose={handleSuccessModalClose}
          aria-labelledby="responsive-dialog-title"
        >
          <DialogContent
            className={styles.successModalContent}
            style={{
              background: `url(${confettiIMG})`,
              backgroundSize: "cover",
              backgroundPosition: "center",
              backgroundRepeat: "no-repeat",
              backdropFilter: "blur(2px)",
            }}
          >
            <img src={tickPop} alt="" />
            <h2>Thank You!</h2>
            <p>Your booking & order is sent to service provider</p>
            {/* <p>You've received 100 OC point</p> */}
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Button
                type="primary"
                onClick={handleSuccessModalClose}
                style={{ marginTop: "24px" }}
              >
                Go to Home
              </Button>
            </div>
          </DialogContent>
        </Dialog>
      )}
      {failedMessage !== "" && (
        <Dialog
          open={isFailureModalVisible}
          onClose={handleFailureModalClose}
          aria-labelledby="responsive-dialog-title"
        >
          <DialogContent
            className={styles.failedModalContent}
            style={{
              // background: `url(${confettiIMG})`,
              backgroundSize: "cover",
              backgroundPosition: "center",
              backgroundRepeat: "no-repeat",
              backdropFilter: "blur(2px)",
            }}
          >
            <img src={FailedTick} alt="" />
            <h2>Payment Failed</h2>
            <p>{failedMessage}</p>
            {/* <p>You've received 100 OC point</p> */}
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Button
                type="primary"
                onClick={handleFailureModalClose}
                style={{ marginTop: "24px" }}
              >
                Go to Home
              </Button>
            </div>
          </DialogContent>
        </Dialog>
      )}
    </div>
  );
}

export default EventConfirmationPage;
