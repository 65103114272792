import React, { useEffect, useState } from "react";
import { Button, Col, Divider, Drawer, Row, Space, Steps, Tag } from "antd";
import Cross from "../../../assests/customIcons/Cross";
import { _GET, _POST } from "../../../common/commonFunc";
import _ from "lodash";
import { Accordion, AccordionDetails, AccordionSummary, Box, Step, StepLabel, Stepper, Typography } from "@mui/material";
import Styles from "../../SupplierScreens/ManageOrders/OrderTable.module.css"
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

interface PropuctReturnedDrawerProps {
    visible: boolean;
    onClose: () => void;
    data: any;
    mainTableData : any
}

const ProductReturnedDrawer: React.FC<PropuctReturnedDrawerProps> = ({
    visible,
    onClose,
    data,
    mainTableData
}) => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [orderItem, setOrderItem] = useState<any>([]);
    // console.log("Data in drawer is : ", data)
    // console.log("MainData in drawer is : ", mainTableData)
    const formatDate = (unixTimestamp: number) => {
        const date = new Date(unixTimestamp * 1000);
        return date.toLocaleDateString("en-US", {
            year: "numeric",
            month: "long",
            day: "numeric",
        });
    };

    const formatTime = (unixTimestamp: number) => {
        const date = new Date(unixTimestamp * 1000);
        return date.toLocaleTimeString("en-US", {
            hour: "2-digit",
            minute: "2-digit",
            hour12: true,
        });
    };

    const confirmReturnService = (event: any) => {

        event.preventDefault();

        setIsModalOpen(true);
    };

 
    const getStatusColor = (status: string) => {
        switch (status) {
            case "ORDERED":
                return "orange";
            case "DELIVERED":
                return "green";
            case "COMPLETED":
                return "green";
            case "SHIPPED":
                return "blue";
            case "PREPARED":
                return "blue";
            case "PENDING_TO_RTD":
                return "grey";
            case "CANCELLED":
                return "grey";
            case "RETURNED":
                return "purple";
            default:
                return "red";
        }
    };

    const trimId = (id: any) => {
        if (id) {

            return id.substring(14, 45);
        }
        return "";
    };

    const orderId = data?.order_id;
    const address = _.get(data, "delivery_address.address", "Address not found");
    const city = _.get(data, "delivery_address.city", "City not found");
    const state = _.get(data, "delivery_address.state", "State not found");
    const country = _.get(data, "delivery_address.country", "Country not found");
    const pincode = _.get(data, "delivery_address.pincode", "Pincode not found");
    const fullAddress = `${address}, ${city}, ${state}, ${country} - ${pincode}`;
    const steps = ['Order Placed', 'Order Shipped', 'Out for delivery', 'Delivered']
    const steps_supplier_courier = ['Order Placed','Order Shipped', 'Delivered']

    const getActiveStep = (status : string) => {
        switch (status) {
            case 'ORDERED':
                return 1;
            case 'PREPARED':
                return 2;
            case 'SHIPPED':
                return 2;
            case 'DELIVERED':
                return 4;
            case 'COMPLETED':
                return 4;
            default:
                return 1; 
        }
    };

    const getActiveStep_supplier = (status : string) => {
        switch (status) {
            case 'ORDERED':
                return 1;
            case 'PREPARED':
                return 2;
            case 'SHIPPED':
                return 2;
            case 'DELIVERED':
                return 3;
            case 'COMPLETED':
                return 3;
            default:
                return 1; 
        }
    };

    useEffect(()=> {

        const filteredOrderItem = data?.productsOrdered?.
        filter((item : any) => mainTableData.order_item_id === item.order_item_id)
        setOrderItem(filteredOrderItem)
        
      },[data, mainTableData])

    return (
        <>
            {/* <ReturnProductsModal
                isVisible={isModalOpen}
                onClose={() => setIsModalOpen(false)}
                // onSubmit={(reason) => handleReturnOrder(data.id, reason)}
                id={orderId}
            /> */}
            <Drawer
                title="Order Details"
                placement="right"
                closable={false}
                onClose={onClose}
                visible={visible}
                key="right"
                width={"550px"}
                height={"100%"}
                extra={
                    <Space style={{ zIndex: 100 }}>
                        <Button onClick={onClose} className="elevatedElement iconBtn">
                            <Cross />
                        </Button>
                    </Space>
                }
            >
                <div style={{ marginBottom: "30px" }}>
                    <Box component="div" sx={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                    {orderItem && <Tag color={getStatusColor(orderItem[0]?.order_item_status)}
                        style={{display:"flex",alignItems:"center"}}>
                            {orderItem[0]?.order_item_status}
                        </Tag>}
                        <Typography variant="subtitle2" color={'text.secondary'} sx={{ display: "flex", flexDirection: "row" }} gutterBottom>
                            Paid <div style={{ color: "grey", marginLeft: "3px", fontWeight: "200" }}>
                                {data?.amount_paid_ccy}
                            </div>
                            <div style={{ color: "black", marginLeft: "3px" }}>
                                {data?.amount_paid}
                            </div>
                        </Typography>
                    </Box>
                    <Box component="div" sx={{ display: "flex", flexDirection: "column" }} marginTop={1} marginBlockEnd={2}>
                        <Typography variant="subtitle2" color={'text.secondary'} sx={{ display: "flex", flexDirection: "row" }} gutterBottom>
                            ID | <div style={{ color: "black", marginLeft: "3px" }}>{trimId(orderId)}</div>
                        </Typography>
                        <Typography variant="caption" color={'text.secondary'} gutterBottom>
                            <div style={{ display: "flex", flexDirection: "row", marginRight: "40px" }}>
                                <div style={{ marginRight: "50px" }}> Order date: {formatDate(data?.purchase_date)}
                                </div>
                                {/* <div> Delivery date: {data?.product_order_details?.deliveryTime?.split("T")[0]}

                                </div> */}
                            </div>
                        </Typography>
                    </Box>
                    {/* dfljgfldkjg */}

                    <Box component="div" sx={{ display: "flex", flexDirection: "column", background: "#f2f1fb", borderRadius: "15px" }}>

                        <Box component="div" sx={{ display: "flex", flexDirection: "column", borderRadius: "15px" }} margin={2}>
                            <Typography variant="subtitle1" fontWeight={600} margin={2}> ({orderItem?.length}) {"Items Returned"} </Typography>
                            <Box component="div" sx={{ display: "flex", flexDirection: "row", background: "#e9e9fa", borderRadius: "15px" }}>



                                <>


                                    <Box sx={{ width: "100%", display: "flex", flexDirection: "column" }}>
                                        {orderItem?.map((order: any, index: any) => (
                                            <>
                                                    <Box sx={{ width: "100%", display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                                                         <Box marginX={2} marginY={1} sx={{ display: "flex", flexDirection: "row", marginLeft: "10px" }}>
                                                        <img
                                                            src={
                                                                order &&
                                                                    order.product_images &&
                                                                    order.product_images.length > 0
                                                                    ? order.product_images[0]
                                                                    : "https://picsum.photos/200"
                                                            }
                                                            alt=""
                                                            style={{ width: "50px", height: "50px", borderRadius: "50%", marginRight: "10px" }}
                                                        />
                                                           <div style={{ display: "flex", flexDirection: "column" }}>
                                                            <Typography variant="subtitle1" fontWeight={400} sx={{ display: "flex", flexDirection: "row" }}>
                                                                {order?.product_name}
                                                            </Typography>
                                                            <Typography variant="caption" color={'text.secondary'} gutterBottom>
                                                                {order.prod_category} | Quantity : {order.units_ordered}
                                                            </Typography>
                                                        </div>
                                                    </Box>

                                                        <div style={{ display: "flex", flexDirection: "row", marginRight: "10px" }}>
                                                            <div style={{ color: "grey", marginLeft: "3px", fontWeight: "200" }}>{order?.amount_paid_ccy}</div>
                                                            <div style={{ color: "black", marginLeft: "3px" }}> {order?.amount_paid}</div>
                                                        </div>
                                                    </Box>
                                          </>    
                                        ))}
                                    </Box>

                                </>

                            </Box>
                        </Box>
   
                    </Box>
                    {/* dlkfjj */}
        
                 
                    <div>
                        <h2>Billing Summary</h2>
                        <p
                            style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "space-between",
                                color: "black",
                                fontSize: "14px",
                                fontWeight: "800",
                            }}
                        >
                            {" "}
                            <span>Subtotal</span>{" "}
                            <span
                                style={{ color: "gray", fontSize: "14px", fontWeight: "800" }}
                            >
                                BHD{" "}
                                {data?.price_per_unit !== null
                                    ? data?.total_product_price_amount
                                    : "0"}
                            </span>
                        </p>
                        <br />
                        <p
                            style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "space-between",
                                color: "black",
                                fontSize: "14px",
                                fontWeight: "800",
                            }}
                        >
                            {" "}
                            <span>Product Discount</span>{" "}
                            <span
                                style={{ color: "gray", fontSize: "14px", fontWeight: "800" }}
                            >
                                {"-"} BHD{" "}
                                {data?.vat_amount !== null ? data?.total_discount_amount : "0"}
                            </span>
                        </p>
                        <br />
                        <p
                            style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "space-between",
                                color: "black",
                                fontSize: "14px",
                                fontWeight: "800",
                            }}
                        >
                            {" "}
                            <span>Shipping Fee </span>{" "}
                            <span
                                style={{ color: "gray", fontSize: "14px", fontWeight: "800" }}
                            >
                                {/* BHD {data?.price_per_unit !== null ? data?.price_per_unit : "0"} */}
                                BHD {data?.shippingValue}
                            </span>
                        </p>
                        <br />
                        <p
                            style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "space-between",
                                color: "black",
                                fontSize: "14px",
                                fontWeight: "800",
                            }}
                        >
                            {" "}
                            <span>VAT Amount</span>{" "}
                            <span
                                style={{ color: "gray", fontSize: "14px", fontWeight: "800" }}
                            >
                                BHD {data?.vat_amount !== null ? data?.total_vat_amount : "0"}
                            </span>
                        </p>
                        <Divider />
                        <br />
                        <p
                            style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "space-between",
                                color: "black",
                                fontSize: "18px",
                                fontWeight: "800",
                            }}
                        >
                            {" "}
                            <span>Total Paid </span>{" "}
                            <span
                                style={{ color: "gray", fontSize: "18px", fontWeight: "800" }}
                            >
                                BHD {data?.amount_paid !== null ? data?.amount_paid : "0"}
                            </span>
                        </p>

                        <Divider />

                    </div>

                    {/* <div>
                        <Button className={Styles.refundButton}>
                            Cancel Order
                        </Button>
                    </div> */}
                </div>
            </Drawer>
        </>
    )
};

export default ProductReturnedDrawer;
