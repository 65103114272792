import React, { useEffect, useState } from "react";
import { Input, Layout, Tabs } from "antd";
import { useHistory } from "react-router-dom";
import OrderTable from "./Orderingtable";
import { _GET } from "../../../common/commonFunc";
import Styles from "../Store/MyStore.module.css";

import SearchIcon from "../../../assests/searchiconnew.svg";
import Filter from "../../../assests/customIcons/Filter";
import {
    IconButton,
} from "@mui/material";

import { filterFreelancers } from "../Utils/Filters";

const { TabPane } = Tabs;

const Orderlisting = () => {
    const [open, setOpen] = useState(false);
    const [zero, setZero] = useState([]);
    const [anchorEl, setAnchorEl] = useState(null);
    const [searchTerm, setSearchTerm] = useState("");
    const [activeTab, setActiveTab] = useState("Pending Orders");
    const [activeSubTab, setActiveSubTab] = useState<any | null>("Octopus Courier");
    const [pendingOrders, setPendingOrders] = useState<any[]>([]);
    const [octopusCourier, setOctopusCourier] = useState<any[]>([]);
    const [ownCourier, setOwnCourier] = useState<any[]>([]);
    const [orderCancelled, setOrderCancelled] = useState<any[]>([]);
    const [orderPrepared, setOrderPrepared] = useState<any[]>([]);
    const [orderHandover, setOrderHandover] = useState<any[]>([]);
    const [orderInTransit, setOrderInTransit] = useState<any[]>([]);
    const [orderCompleted, setOrderCompleted] = useState<any[]>([]);
    const [returnOrder, setReturnOrder] = useState<any[]>([]);
    const [pendinToRTD, setPendingToRTD] = useState<any[]>([]);


    const getOrderList = async () => {
        try {
            const res = await _GET(
                `supplier/fetch/orders`,
                true,
                false
            );
            if (res && res.status && res.data) {


                setPendingOrders(
                    res.data.filter(
                        (order: any) => order.order_item_status === "ORDERED"
                    )
                );

                setOrderPrepared(
                    res.data.filter(
                        (order: any) => order.order_item_status === "PREPARED"
                    )
                );

                setOrderHandover(
                    res.data.filter(
                        (order: any) => order.order_item_status === "SHIPPED"
                    )
                );
                setOrderInTransit(
                    res.data.filter(
                        (order: any) => (order.order_item_status === "PREPARED") || (order.order_item_status === "SHIPPED")
                    )
                )
                setOrderCompleted(
                    res.data.filter(
                        (order: any) => order.order_item_status === "DELIVERED" || order.order_item_status === "COMPLETED"
                    )
                )
                setOrderCancelled(
                    res.data.filter(
                        (order: any) => order.order_item_status === "CANCELLED" || order.order_status === "CANCELLED"
                    )
                )
            }
        } catch (error) {
            console.log("Error fetching orders:", error);
        }
    };

    const getReturnOrder = async () => {
        try {
            const res = await _GET(
                `supplier/allProductOrderReturnRequest`,
                true,
                false
            );
            if (res && res.status && res.data) {

                setReturnOrder(
                    res.data.filter(
                        (order: any) => order.order_item_status === "RETURNED" && order?.return_status?.toLowerCase() === "pending"
                    )
                );
                setPendingToRTD(
                    res.data.filter(
                        (order: any) => order.order_item_status === "PENDING_TO_RTD" || order?.return_status?.toLowerCase() === "accepted"
                    )
                )
            }
        } catch (error) {
            console.log("Error fetching returned orders:", error);
        }
    };

    useEffect(() => {
        getOrderList();
        getReturnOrder();
    }, []);

    useEffect(() => {
        setOctopusCourier(
            pendingOrders.filter(
                (courier: any) => courier.courier_service_choice === "octopus_courier_service"
            )
        );
        setOwnCourier(
            pendingOrders.filter(
                (courier: any) => courier.courier_service_choice === "supplier_courier_service"
            )
        )
    }, [pendingOrders]);


    const handleTabChange = (status: any) => {
        setActiveTab(status);
        // if (status !== "Pending Orders") {
        //     setActiveSubTab(null);
        // }

    };

    const handleSubTabChange = (status: any) => {
        setActiveSubTab(status);
    };

    const history = useHistory();

    const returnOrderHeader = [
        { id: "transaction_id", label: "TRANSACTION ID", width: "15%" },
        { id: "product_img", label: "PRODUCT", width: "20%" },
        { id: "return_category", label: "RETURN CATEGORY", width: "20%" },
        {
            id: "price",
            label: "PRICE",
            width: "15%",
        },
        { id: "action", label: "ACTION", width: "30%" },
    ];

    const pendingOrderHeader = [
        { id: "transaction_id", label: "TRANSACTION ID", width: "10%" },
        { id: "product", label: "PRODUCT", width: "20%" },
        { id: "order_date", label: "ORDER DATE", width: "10%" },
        {
            id: "quantity",
            label: "QUANTITY",
            width: "10%",
        },
        {
            id: "paid",
            label: "PAID",
            width: "10%",
        },
        { id: "action", label: "ACTION", width: "15%" },
    ]

    const preparedOrderHeader = [
        { id: "transaction_id", label: "TRANSACTION ID", width: "15%" },
        { id: "product", label: "PRODUCT", width: "20%" },
        { id: "sheduled_date_and_time", label: "SCHEDULED DATE AND TIME", width: "20%" },
        {
            id: "quantity",
            label: "QUANTITY",
            width: "10%",
        },
        {
            id: "logisctic_partner",
            label: "LOGISTIC PARTNER",
            width: "15%",
        },
        { id: "action", label: "ACTION", width: "15%" },
    ]


    const pendingToRTDHeader = [
        { id: "transaction_id", label: "TRANSACTION ID", width: "15%" },
        { id: "product_img", label: "PRODUCT", width: "20%" },
        { id: "handover_date_time", label: "HANDOVER DATE AND TIME", width: "20%" },
        {
            id: "quantity",
            label: "QUANTITY",
            width: "10%",
        },
        {
            id: "logisctic_partner",
            label: "LOGISTIC PARTNER",
            width: "15%",
        },
        { id: "action", label: "ACTION", width: "15%" },
    ]

    const orderHandoverHeader = [
        { id: "transaction_id", label: "TRANSACTION ID", width: "15%" },
        { id: "product", label: "PRODUCT", width: "20%" },
        { id: "handover_date_time", label: "HANDOVER DATE AND TIME", width: "20%" },
        {
            id: "quantity",
            label: "QUANTITY",
            width: "10%",
        },
        {
            id: "logisctic_partner",
            label: "LOGISTIC PARTNER",
            width: "15%",
        },
        { id: "action", label: "ACTION", width: "15%" },
    ]


    const order_completedHeader = [

        { id: "transaction_id", label: "TRANSACTION ID", width: "10%" },
        { id: "product", label: "PRODUCT", width: "20%" },
        { id: "completed_date_time", label: "COMPLETED DATE AND TIME", width: "20%" },
        {
            id: "quantity",
            label: "QUANTITY",
            width: "10%",
        },
        {
            id: "logisctic_partner",
            label: "LOGISTIC PARTNER",
            width: "15%",
        },
        { id: "action", label: "ACTION", width: "15%" }

    ]

    const order_cancelledHeader = [

        { id: "transaction_id", label: "TRANSACTION ID", width: "20%" },
        { id: "product", label: "PRODUCT", width: "20%" },
        { id: "cancelled_date_time", label: "CANCELLED ON", width: "20%" },
        {
            id: "quantity",
            label: "QUANTITY",
            width: "20%",
        },
        // { id: "action", label: "ACTION", width: "20%" }

    ]



    return (
        <Layout
            style={{
                height: "100vh",
                backgroundColor: "#F5F4FA",
            }}
            className={Styles.customLayoutBG}
        >
            <div className={Styles.header}>
                <h1>Manage Orders</h1>
                <div className={Styles.serviceHeader}>

                    <div className={Styles.actionsContainerNext}>
                        <div className={Styles.searchDiv}>
                            <img src={SearchIcon} alt="SearchIcon" />
                            <Input
                                placeholder="Search by ID, Name..."
                                value={searchTerm}
                                onChange={(e) => setSearchTerm(e.target.value)}
                                className={Styles.searchInput}
                            />

                            <IconButton>
                                <Filter />
                            </IconButton>
                        </div>
                    </div>
                </div>

            </div>

            <div className={Styles.actionsContainerMain}>
                <Tabs activeKey={activeTab} onChange={handleTabChange}>

                    <TabPane
                        tab={
                            <span className={Styles.countingSpanParent}>
                                PENDING ORDERS{" "}
                                <span className={Styles.countingSpan}>
                                    {pendingOrders ? pendingOrders.length : 0}
                                </span>
                            </span>
                        }
                        key="Pending Orders"
                    />

                    {/* <TabPane
                        tab={
                            <span className={Styles.countingSpanParent}>
                                PREPARED ORDERS{" "}
                                <span className={Styles.countingSpan}>
                                    {orderPrepared ? orderPrepared.length : 0}
                                </span>
                            </span>
                        }
                        key="Prepared Orders"
                    />

                    <TabPane
                        tab={
                            <span className={Styles.countingSpanParent}>
                                ORDER HANDOVER 
                                <span className={Styles.countingSpan}>
                                    {orderHandover ? orderHandover.length : 0}
                                </span>
                            </span>
                        }
                        key="Order Handover"
                    /> */}

                    <TabPane
                        tab={
                            <span className={Styles.countingSpanParent}>
                                ORDERS IN-TRANSIT
                                <span className={Styles.countingSpan}>
                                    {orderInTransit ? orderInTransit.length : 0}
                                </span>
                            </span>
                        }
                        key="Order InTransit"
                    />

                    <TabPane
                        tab={
                            <span className={Styles.countingSpanParent}>
                                COMPLETED ORDERS
                                <span className={Styles.countingSpan}>
                                    {orderCompleted ? orderCompleted.length : 0}
                                </span>
                            </span>
                        }
                        key="Order Completed"
                    />
                        <TabPane
                        tab={
                            <span className={Styles.countingSpanParent}>
                                CANCELLED ORDERS{" "}
                                <span className={Styles.countingSpan}>
                                    {orderCancelled ? orderCancelled.length : 0}
                                </span>
                            </span>
                        }
                        key="Orders Cancelled"
                    />

                    <TabPane
                        tab={<span className={Styles.separator}>|</span>}
                        disabled
                        key="Separator"
                    />

                    <TabPane
                        tab={
                            <span className={Styles.countingSpanParent}>
                                RETURN ORDERS{" "}
                                <span className={Styles.countingSpan}>
                                    {returnOrder ? returnOrder.length : 0}
                                </span>
                            </span>
                        }
                        key="Return Order"
                    />

                    <TabPane
                        tab={
                            <span className={Styles.countingSpanParent}>
                                PENDING TO RTD
                                <span className={Styles.countingSpan}>
                                    {pendinToRTD ? pendinToRTD?.length : 0}
                                </span>
                            </span>
                        }
                        key="Pending To Rtd"
                    />

                    <TabPane
                        tab={
                            <span className={Styles.countingSpanParent}>
                                RETURN COMPLETE <span className={Styles.countingSpan}>0</span>
                            </span>
                        }
                        key="Return Complete"
                    />


                </Tabs>
            </div>

            <div
                style={{ height: "auto", width: "100%" }}
                className={Styles.parentContentdiv_}
            >
                {/* {activeTab === "Pending Orders" && (
                    // <OrderTable headers={pendingOrderHeader}
                    //     data={pendingOrders} status="pendingOrders"
                    //     getOrderList={getOrderList} />
                    <Tabs activeKey={activeTab} onChange={handleTabChange}>

                    <TabPane
                        tab={
                            <span className={Styles.countingSpanParent}>
                                 Octopus Courier{" "}
                                <span className={Styles.countingSpan}>
                                    {octopusCourier ? octopusCourier.length : 0}
                                </span>
                            </span>
                        }
                        key="Octopus Courier"
                    />
                         <TabPane
                        tab={<span className={Styles.separator}>|</span>}
                        disabled
                        key="Separator"
                    />
                       <TabPane
                        tab={
                            <span className={Styles.countingSpanParent}>
                                 Own Courier{" "}
                                <span className={Styles.countingSpan}>
                                    {ownCourier ? ownCourier.length : 0}
                                </span>
                            </span>
                        }
                        key="Own Courier"
                    />
                    </Tabs>
                )} */}

                {activeTab === "Pending Orders" && (
                    <>
                        <Tabs activeKey={activeSubTab} onChange={handleSubTabChange}>
                            <TabPane
                                tab={
                                    <span className={Styles.countingSpanParent}>
                                        Octopus Courier{" "}
                                        <span className={Styles.countingSpan}>
                                            {octopusCourier ? octopusCourier.length : 0}
                                        </span>
                                    </span>
                                }
                                key="Octopus Courier"
                            />
                            <TabPane
                                tab={<span className={Styles.separator}>|</span>}
                                disabled
                                key="Separator"
                            />
                            <TabPane
                                tab={
                                    <span className={Styles.countingSpanParent}>
                                        Own Courier{" "}
                                        <span className={Styles.countingSpan}>
                                            {ownCourier ? ownCourier.length : 0}
                                        </span>
                                    </span>
                                }
                                key="Own Courier"
                            />
                        </Tabs>

                        {activeSubTab === "Octopus Courier" && (
                            <OrderTable
                                headers={pendingOrderHeader}
                                data={octopusCourier}
                                status="pendingOrders-octopus"
                                getOrderList={getOrderList}
                            />
                        )}
                        {activeSubTab === "Own Courier" && (
                            <OrderTable
                                headers={pendingOrderHeader}
                                data={ownCourier}
                                status="pendingOrders-own"
                                getOrderList={getOrderList}
                            />
                        )}
                    </>
                )}

                {activeTab === "Prepared Orders" && (
                    <OrderTable headers={preparedOrderHeader}
                        data={orderPrepared} status="preparedOrders"
                        getOrderList={getOrderList} />
                )}
                {activeTab === "Order Handover" && (
                    <OrderTable headers={orderHandoverHeader}
                        data={orderHandover} status="orderHandover"
                        getOrderList={getOrderList} />
                )}
                {activeTab === "Order InTransit" && (
                    <OrderTable headers={orderHandoverHeader}
                        data={orderInTransit} status="orderInTransit"
                        getOrderList={getOrderList} />
                )}
                {activeTab === "Order Completed" && (
                    <OrderTable headers={order_completedHeader}
                        data={orderCompleted} status="orderCompleted"
                        getOrderList={getOrderList} />
                )}
                  {activeTab === "Orders Cancelled" && (
                    <OrderTable headers={order_cancelledHeader}
                        data={orderCancelled} status="cancelledOrders"
                        getOrderList={getOrderList} />
                )}
                {activeTab === "Return Order" && (
                    <OrderTable
                        headers={returnOrderHeader}
                        data={returnOrder}
                        status="returnOrder"
                        getOrderList={getReturnOrder}
                    />
                )}
                {activeTab === "Pending To Rtd" && (
                    <OrderTable headers={pendingToRTDHeader}
                        data={pendinToRTD} status="pendingToRtd"
                        getOrderList={getReturnOrder} />
                )}
                {activeTab === "Return Complete" && (
                    <OrderTable headers={pendingToRTDHeader}
                        data={zero} status="returnComplete"
                        getOrderList={getReturnOrder} />
                )}



            </div>
        </Layout>
    );
};

export default Orderlisting;
function filteredAllServiceData(freelancers: never[], searchTerm: string) {
    throw new Error("Function not implemented.");
}
