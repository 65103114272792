import React, { useEffect, useState } from "react";
import { Modal, Form, Input, Button, notification, message } from "antd";
import CustomDeleteMark from "../../../assests/customDeleteMark.svg";
import styles from "../components/PopupForm.module.css";
import Cross from "../../../assests/customIcons/Cross";
import { CloseOutlined, InfoCircleOutlined } from "@ant-design/icons";
import Caution from "../../../assests/delete_icon.png"
import FileUpload from "../../../components/InputComponents/FileUpload";
import { Select } from "antd/lib";
import { _GET, _POST } from "../../../common/commonFunc";
import Categories from "../../Freelancer/Categories/Categories";
import { showSuccessCustomModal } from "../../../common/CustomModal/CustomModal";

type PopupProps = {
  isVisible: boolean;
  onClose: () => void;
  id?: string;
  item_id: string;
  fetchData : () => void;
};

const ReturnProductsModal: React.FC<PopupProps> = ({
  isVisible,
  onClose,
  id,
  item_id,
  fetchData
}) => {
  const [form] = Form.useForm();
  const [showPolicyDetails, setShowPolicyDetails] = useState(false);
  const [returnImages, setReturnImages] = useState<any>([]);
  const [reasons, setReasons] = useState<any>([]);
  const [selectedReason, setSelectedReason] = useState({
    id: "",
    name: "",
  });

  
  const togglePolicyDetails = () => {
    setShowPolicyDetails((prev) => !prev);
  };

  const getUpdatedImagesMain = async () => {
    if (returnImages.length > 0) {
      const allValues = {
        images: returnImages,
      };

      const mainValues = await convertDataToFormData(allValues);

      const response = await _POST(
        "services/uploadServiceImagesToS3",
        mainValues,
        false
      );
      if (response) {
        if (response.data?.length > 0) {
          return response.data;
        } else {
          notification.open({
            type: "error",
            message: "Something Went Wrong",
            description: "Please try again to upload your images",
          });
        }
      }
    } else {
      return [];
    }
  };

  const convertDataToFormData = async (data: any) => {
    const formData = new FormData();
    if (data) {
      Object.keys(data).forEach((ele: any) => {
        if (ele === "images") {
          data[ele].forEach((image: any) => {
            formData.append("images", image);
          });
        } else {
          formData.append(ele, data[ele]);
        }
      });
    }
    return formData;
  };

  const updateServiceImagesMain = (data: any) => {
    const index = Object.keys(data)[0];
    const value = data[index];
    if (value) setReturnImages((prevImages: any[]) => [...prevImages, value]);
    else {
      const updatedImages = returnImages;
      updatedImages.splice(index, 1);
      setReturnImages(updatedImages);
    }
  };

  const getReturnReasons = async () => {
    try {
      const res = await _GET("supplier/cancel/product/category", true, false);
      if (res.statusCode === 200) {
        setReasons(res.data);
      } else {
        notification.error({
          message: res.message,
        });
      }
    } catch (error) {
      console.log("error :", error);
    }
  };

  useEffect(() => {
    getReturnReasons();
  }, []);

  const handleReasonChange = (value: any) => {
    const category = reasons.find(
      (cat: any) => cat.category_id === value
    );
    setSelectedReason({
      id: category?.category_id,
      name: category?.category_name,
    });
  };


  const onSubmit = async () => {
    try {
      await form.validateFields().then(async (values) => {
        const uploadedImages = await getUpdatedImagesMain();
  
        if (uploadedImages.length === 0) {
          notification.error({
            message: "Error",
            description: "Please add images of the product you want to return.",
          });
          return;
        }
  
        const payload = {
          order_id: id,
          order_item_id: item_id,
          return_reason: values.return_reason,
          images: uploadedImages,
          return_category: selectedReason.name,
          return_category_id: selectedReason.id,
         
        };
  console.log("Payload for return is : ", payload)
        const returnProductResponse = await _POST(
          "supplier/returnOrderItem",
          payload,
          true
        );
  
        if (returnProductResponse.data === true && returnProductResponse.statusCode === 200) {
          notification.success({
            message: "Product Returned Successfully",
            description: "Your product has been returned successfully.",
          });
          form.resetFields();
          closeModal();
          fetchData();
        } else {
          notification.error({
            message: "Return Product Failed",
            description: "An error occurred while returning the product.",
          });
        }
      }).catch((error) => {
        console.error("Form validation error:", error);
        notification.error({
          message: "Validation Error",
          description: "Please fill in the required fields.",
        })
      });
    } catch (error) {
      console.error("Error during onSubmit:", error);
      notification.error({
        message: "Submission Error",
        description: "An error occurred during submission.",
      });
      form.resetFields();
      closeModal();
    }
  };
  
  const closeModal = () => {
    onClose();
    setShowPolicyDetails(false)
    console.log("Before reset:", form.getFieldsValue());
    form.resetFields();
    console.log("After reset:", form.getFieldsValue());
    setReturnImages([]);
    setReasons([]);
  }

  return (
    <Modal
      open={isVisible}
      onCancel={closeModal}
      footer={null}
      className={styles.modal}
      centered
    >
      <Button
        className={`${styles.closeIcon} elevatedElement iconBtn`}
        onClick={onClose}
      >
        <Cross />
      </Button>
      <div style={{display:"flex", alignItems:"center", justifyContent:"center", flexDirection:"column"}}>
          <img src={Caution} alt="!" style={{height:"75px", width:"75px"}}/>
          <h2 style={{marginBottom:0}}>
            Are you sure you want </h2>
            <h2 style={{marginTop:0}}>
            to return order?
          </h2>
          <FileUpload
            title1="Upload Image"
            description="PDF, JPG, PNG file with max size of 5MB."
            setData={updateServiceImagesMain}
            name="serviceImages"
            uploadMultiple
          />
            </div>
      <Form
        form={form}
        layout="vertical"
        name="reasonForm"
        className={styles.form}
      >
        <Form.Item
          name="reason"
          rules={[{ required: true, message: "Please select a reason!" }]}
        >
             <Select
                    placeholder="Select a reason"
                    onChange={async (value) => {
                      handleReasonChange(value); 
                    }}
                    options={reasons.map((category: any) => ({
                      label: category.category_name,
                      value: category.category_id,
                    }))}
                    className={styles.formSelect}
                    filterOption={(input: any, option: any) =>
                      (option?.label ?? "")
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                  />
        </Form.Item>
        <Form.Item
          name="return_reason"
          rules={[{ required: true, message: "Please enter the reason!" }]}
        >
          <Input.TextArea
            rows={4}
            placeholder="Give a reason..."
            className={styles.textArea}
           
          />
        </Form.Item>
        <div className={styles.footer}>
          <Button
            key="back"
            onClick={togglePolicyDetails}
            className={styles.policyButton}
            icon={<InfoCircleOutlined />}
          >
            <span className={styles.cancelText}>Policy</span>
          </Button>

          <Button
            key="submit"
            type="primary"
            onClick={onSubmit}
            className={styles.submitButton}
          >
            Send
          </Button>
        </div>
        {showPolicyDetails && (
          <div className={styles.policyDetails}>
            <h4 className={styles.policyHeader}>Return Policy</h4>
            <ul className={styles.policyList}>
              {/* <li>
                Discounted items are final and cannot be returned or exchanged.
              </li> */}
              <li>
               Returned items must have tags still on and be returned in original product packaging.
              </li>
              <li>
              Returned items must have no visible signs of wear or use.
              </li>
            </ul>
          </div>
        )}
      </Form>
    </Modal>
  );
};

export default ReturnProductsModal;
