import react, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Tabs,
  Table,
  Button,
  Input,
  Divider,
  Spin,
  Row,
  Col,
  Card,
} from "antd";
import Modal from "@mui/material/Modal";
import {
  EyeOutlined,
  CalendarOutlined,
  CloseOutlined,
  DollarCircleOutlined,
  FilterOutlined,
  StarFilled,
  StarOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import MobileTopDashboardNavBar from "../../../common/DashboardNavBar/mobileTopBar";
import DashboardNavBar from "../../../common/DashboardNavBar/DashboardNavBar";
import { _GET, _POST, getUserId } from "../../../common/commonFunc";
import CustomTable from "../components/CustomTable";
import Styles from "./CustomerOrderPage.module.css";
import {
  filterUpcomingServices,
  filterInProgressServices,
  filterCompletedServices,
  filterCancelledServices,
} from "../Utils/filterUtils";
import {
  filterUpcomingevents,
  filterInProgressevents,
  filterCompletedevents,
  filterCancelledevents,
} from "../Utils/filterEventUtils";

import {
  setCartEventItems,
  setCartItems,
} from "../../ServiceProvioder/redux/actions/bookingActions";
import { debounce, get } from "lodash";
import EventTable from "../components/Eventtable";
import { Upcoming } from "@mui/icons-material";
import SearchIcon from "../../../assests/searchiconnew.svg";
import Filter from "../../../assests/customIcons/Filter";
import {
  FormControlLabel,
  IconButton,
  Menu,
  MenuItem,
  Radio,
  RadioGroup,
} from "@mui/material";
import { RootState } from "../../../redux/store";
import { APPLICATION_ROUTES } from "../../../constant/ApplicationRoutes";
import GenericPage from "../../SupplierScreens/Profile/GenericPage";
import Cardcontainer from "../../ServiceProvioder/Order/Cardcontainer";
import { useHistory, useLocation } from "react-router";
import Eventcartcontainer from "../../ServiceProvioder/Order/EventCardContainer";
import { ReviewDrawer } from "./ServiceReviewDrawer";
import { EventReviewDrawer } from "./EventReviewDrawer";
import { EditServiceReviewDrawer } from "./EditServiceReviewDrawer";
import { EditEventReviewDrawer } from "./EditEventReviewDrawer";
import ProductCartContainer from "../../ServiceProvioder/Order/ProductCardContainer";
import ProductsTable from "../components/ProductsTable";
import OrderCart from "../../ServiceProvioder/Order/OrderCart";
import Cross from "../../../assests/customIcons/Cross";
import DetailsPage from "../../ServiceProvioder/Order/detailPage";
import UserEventDrawer from "../../ServiceProvioder/EventDetails/UserEventDrawer";
import ProductDetailsDrawer from "../components/ProductDetailsDrawer";

const { TabPane } = Tabs;

interface Cartitem {
  itemType: string;
  itemId: string;
  type: string;
  quantity: number;
  service_category_main: string;
  service_category_sub: string;
}

interface LocationState {
  activeTab?: string;
}

const CustomerOrderPage = () => {
  const userId = getUserId();
  const history = useHistory();
  const [serviceData, setServiceData] = useState<any>([]);
  const [upComingServices, setUpComingServices] = useState<any>([]);
  const [inProgressServices, setInProgressServices] = useState([]);
  const [completedServices, setCompletedServices] = useState([]);
  const [cancelledServices, setCancelledServices] = useState([]);
  const [requestQuotes, setRequestedQuotes] = useState([]);
  const [activeTab, setActiveTab] = useState("Upcoming");
  const [activeFilter, setActiveFilter] = useState("Service");
  // const [searchTerm, setSearchTerm] = useState("");
  const [showDropdown, setShowDropdown] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  const [eventData, setEventData] = useState<any>([]);
  const [eventItems, setEventItems] = useState([]);
  const [upComingEvents, setUpComingEvents] = useState([]);
  const [inProgressEvents, setInProgressEvents] = useState([]);
  const [completedEvents, setCompletedEvents] = useState([]);
  const [cancelledEvents, setCancelledEvents] = useState([]);
  const [serviceReviewDrawer, setServiceReviewDrawer] = useState(false);
  const [eventReviewDrawer, setEventReviewDrawer] = useState(false);
  const [questionsData, setQuestionsData] = useState([]);
  const [editService, setEditService] = useState(false);
  const [editEvent, setEditEvent] = useState(false);
  const [productCart, setProductCart] = useState<any>([]);
  const [productOrdersData, setProductOrdersData] = useState([]);
  const [zero, setZero] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [serviceDataSearch, setServiceDataSearch] = useState<any[]>([]);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const [isSearched, setIsSearched] = useState(false);
  const [data, setData] = useState<any>(null);
  const [openDrawer1, setOpenDrawer] = useState(false);
  const [openEventDrawer, setOpenEventDrawer] = useState(false);
  const [productDetails, setProductDetails] = useState([]);
  const [userEventData, setUserEventData] = useState<any>([]);
  const [userEventDrawerVisible, setUserEventDrawerVisible] = useState(false);

  const inputRef = useRef<any>(null);

  const showModal = () => {
    setIsModalVisible(true);
    inputRef.current?.focus();
  };

  const handleModalClose = () => {
    setIsModalVisible(false);
    setServiceDataSearch([]);
    setSearchTerm("");
  };

  const handleSearchInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setSearchTerm(value);
    if (value) {
      debouncedSearch(value);
    } else {
      setServiceDataSearch([]);
    }
  };

  useEffect(() => {
    if (isModalVisible) {
      setTimeout(() => {
        inputRef.current?.focus();
      }, 0);
    }
  }, [isModalVisible]);

  const debouncedSearch = debounce(async (searchValue: string) => {
    setLoading(true);
    try {
      const payload = {
        searchTerm: searchValue,
        limit: 10,
        page: 1,
        userId: userId,
      };

      const res = await _POST("search/octopus/orders", payload, true);
      if (res?.data?.results) {
        setServiceDataSearch(res.data.results);
        setIsSearched(true);
      }
    } catch (error) {
      console.error("Search API error", error);
    } finally {
      setLoading(false);
    }
  }, 500);

  const location = useLocation<LocationState>();

  const updatedBookings = useSelector(
    (state: RootState) => state.booking.bookings
  );

  const cartItems: any = useSelector(
    (state: RootState) => state.cart.cartItems
  );

  const cartEventItems: any = useSelector(
    (state: RootState) => state.cart.eventCartItems
  );

  useEffect(() => {
    if (location.state && location.state.activeTab) {
      setActiveTab(location.state.activeTab);
    }
  }, [location.state]);

  useEffect(() => {
    if (updatedBookings) {
      getServices();
    }
  }, [updatedBookings]);

  const getServices = async () => {
    const res = await _GET(
      `userProfile/getUsersServiceBookings/${userId}`,
      true,
      false
    );
    setServiceData(res.data);
  };
  const getQuestions = async (entityType: any) => {
    try {
      const res = await _GET(
        `review/questions?entity_type=${entityType}&version=1`,
        true,
        false
      );
      setQuestionsData(res?.data);
    } catch (error) {
      console.error("Error fetching questions:", error);
    }
  };
  useEffect(() => {
    if (activeFilter.toLowerCase() !== "orders") {
      const entityType = activeFilter.toLowerCase();
      getQuestions(entityType);
    }
  }, [activeFilter]);

  const getEvents = async () => {
    try {
      const res = await _GET(
        `userProfile/getUserAllEventBookings`,
        true,
        false
      );
      console.log("RES DATA IS", res);
      setEventData(res.data);
    } catch (error) {
      console.error("Error fetching events:", error);
    }
  };

  const fetchCartItem = async () => {
    try {
      const res = await _GET(`cart/getItem/${userId}`, true, false);

      if (res?.status) {
        const itemsArray: Cartitem[] = Object.values(res.data)
          .map((item: any) => JSON.parse(item))
          .flat();

        const serviceItems = itemsArray.filter(
          (item) => item.itemType === "service"
        );
        const eventItems: any = itemsArray.filter(
          (item) => item.itemType === "event"
        );
        const productItems: any = itemsArray.filter(
          (item) => item.itemType === "product"
        );
        setProductCart(productItems);
        setEventItems(eventItems);
        dispatch(setCartItems(serviceItems));
        dispatch(setCartEventItems(eventItems));
      }
    } catch (error) {
      console.error(error);
    }
  };

  const fetchOrders = async () => {
    try {
      const res = await _GET(`supplier/customer/fetch/orders`, true, false);
      console.log("ORDERS DATA IS", res);
      if (res.status) {
        setProductOrdersData(res.data);
      }
    } catch (error) {
      console.error(error);
    }
  };
  useEffect(() => {
    fetchOrders();
  }, []);
  console.log("PRODUCT ORDERS DATA IS ", productOrdersData);

  const fetchQuotes = async () => {
    try {
      const res = await _GET(`services/quote/request`, true, false);
      console.log("QUOTES DATA IS", res);
      if (res.status && res.statusCode === 200 && res.data !== null) {
        setRequestedQuotes(res.data);
      }
    } catch (error) {
      console.error(error);
    }
  };
  useEffect(() => {
    fetchQuotes();
  }, []);

  const mappedData = productOrdersData
    .filter((order: any) => (order.order_item_status === "ORDERED" || order.order_item_status === "PREPARED" || order.order_item_status === "SHIPPED") && order.order_status !== "CANCELLED")
    .map((item: any) => {
      const { floor, address, country, landmark, building_no } =
        item.delivery_address;
      const shippingAddress = `${floor}, ${address}, ${landmark}, ${building_no}, ${country}`;

      return {
        order_id: item.order_id,
        orders: item.product_name,
        purchase_date: item?.purchase_date,
        delivery_on: "",
        amount_paid: item.amount_paid,
        status: item.payment_status,
        delivery_address: shippingAddress,
        product_images: item.product_images,
        description: item.description,
        product_name: item.product_name,
        prod_category: item.prod_category,
        order_item_status : item.order_item_status,
        order_item_id : item.order_item_id
      };
    });

  const deliveredData = productOrdersData.filter(
    (order: any) => order.order_item_status === "DELIVERED" || order.order_item_status === "COMPLETED"
  );
  const returnedData = productOrdersData.filter(
    (order: any) =>  order.order_item_status === "RETURNED" || order.order_item_status === "PENDING_TO_RTD"
  );
  const cancelledData = productOrdersData.filter(
    (order: any) => order.order_status === "CANCELLED"
  );

  console.log("Returned and cancelled data is : ", returnedData, cancelledData);

  console.log("MAPPED DATA IS ", mappedData);

  console.log("Delivered DATA IS ", deliveredData);

  useEffect(() => {
    console.log("PRODUCT CART", productCart);
    getEvents();
    fetchCartItem();
  }, [userId]);

  useEffect(() => {
    getServices();
  }, [userId]);

  const handleInputFocus = () => {
    setShowDropdown(true);
  };

  const handleDropdownSelect = (selection: any) => {
    setShowDropdown(false);
    setSearchTerm(selection);

    if (selection === "/service :") {
      setActiveFilter("Services");
      setActiveTab("Upcoming");
    } else if (selection === "/event :") {
      setActiveFilter("Events");
      setActiveTab("Upcoming");
    } else if (selection === "/product :") {
      setActiveFilter("Orders");
      setActiveTab("Active");
    }
  };
  const closeDrawer = () => {
    setServiceReviewDrawer(false);
  };
  const openDrawer = () => {
    setServiceReviewDrawer(true);
  };
  const openEventReviewDrawer = () => {
    setEventReviewDrawer(true);
  };
  const closeEventReviewDrawer = () => {
    setEventReviewDrawer(false);
  };
  const openServiceEditDrawer = () => {
    setEditService(true);
  };
  const closeServiceEditDrawer = () => {
    setEditService(false);
  };
  const openEventEditDrawer = () => {
    setEditEvent(true);
  };
  const closeEventEditDrawer = () => {
    setEditEvent(false);
  };

  const mapServiceData = (data: any) => {
    if (data) {
      const upComing = data.filter(
        (service: any) => service.booking_status === "Upcoming"
      );
      const inProgress = data.filter(
        (service: any) => service.booking_status === "inProgress"
      );
      const completed = data.filter(
        (service: any) => service.booking_status === "Completed"
      );
      const cancelled = data.filter(
        (service: any) => service.booking_status === "Cancelled"
      );

      setUpComingServices(upComing);
      setInProgressServices(inProgress);
      setCompletedServices(completed);
      setCancelledServices(cancelled);
    }
  };

  useEffect(() => {
    if (serviceData.length > 0) {
      mapServiceData(serviceData);
    }
  }, [serviceData]);

  const mapEventData = (data: any) => {
    if (data) {
      const upComing = data.filter(
        (event: any) => event.event_booking_status === "Upcoming"
      );
      const inProgress = data.filter(
        (event: any) => event.event_booking_status === "inProgress"
      );
      const completed = data.filter(
        (event: any) => event.event_booking_status === "Completed"
      );
      const cancelled = data.filter(
        (event: any) => event.event_booking_status === "Cancelled"
      );

      setUpComingEvents(upComing);
      setInProgressEvents(inProgress);
      setCompletedEvents(completed);
      setCancelledEvents(cancelled);
    }
  };

  useEffect(() => {
    if (eventData.length > 0) {
      mapEventData(eventData);
    }
  }, [eventData]);

  if (!serviceData) {
    return <div>Loading...</div>;
  }
  const completedServiceHeaders = [
    { id: "id", label: "ID", width: "10%" },
    { id: "service_name", label: "SERVICE NAME", width: "15%" },
    { id: "booking_date_time", label: "BOOKING DATE", width: "15%" },
    { id: "service_location", label: "SERVICE LOCATION", width: "17%" },
    { id: "rating", label: "RATING", width: "8%" },
    { id: "pay", label: "FEES", width: "12%" },
    { id: "action", label: "ACTION", width: "25%" },
  ];
  const completedEventHeaders = [
    { id: "id", label: "ID", width: "10%" },
    { id: "event_name", label: "EVENT NAME", width: "12%" },
    { id: "booking_date_time", label: "BOOKING DATE", width: "8%" },
    { id: "paid", label: "PAID", width: "5%" },
    { id: "event_location", label: "EVENT LOCATION", width: "20%" },
    { id: "rating", label: "RATING", width: "10%" },
    { id: "event", label: "EVENT", width: "12%" },
    { id: "booked_seats", label: "BOOKED SEAT", width: "5%" },
    { id: "action", label: "ACTION", width: "20%" },
  ];
  const headers = [
    { id: "id", label: "ID", width: "5%" },
    { id: "service_name", label: "SERVICE NAME", width: "20%" },
    { id: "booking_date_time", label: "BOOKING DATE", width: "15%" },
    { id: "service_location", label: "SERVICE LOCATION", width: "15%" },
    // { id: "rating", label: "RATING", width: "15%" },
    { id: "pay", label: "FEES", width: "15%" },
    { id: "action", label: "ACTION", width: "25%" },
  ];

  const eventHeaders = [
    { id: "id", label: "ID", width: "10%" },
    { id: "event_name", label: "EVENT NAME", width: "10%" },
    { id: "booking_date_time", label: "BOOKING DATE", width: "10%" },
    { id: "paid", label: "PAID", width: "5%" },
    { id: "event_location", label: "EVENT LOCATION", width: "15%" },
    // { id: "rating", label: "RATING", width: "10%" },
    { id: "event", label: "EVENT", width: "15%" },
    { id: "booked_seats", label: "BOOKED SEAT", width: "5%" },
    { id: "action", label: "ACTION", width: "20%" },
  ];
  const productHeaders = [
    { id: "id", label: "ID", width: "10%" },
    { id: "orders", label: "ORDERS", width: "20%" },
    { id: "order_date", label: "ORDER DATE", width: "10%" },
    { id: "paid", label: "AMOUNT PAID", width: "8%" },
    { id: "status", label: "STATUS", width: "10%" },
    { id: "shipping_address", label: "SHIPPING ADDRESS", width: "20%" },
    { id: "action", label: "ACTION", width: "20%" },
  ];

  const productDeliveredHeaders = [
    { id: "id", label: "ID", width: "15%" },
    { id: "orders", label: "ORDERS", width: "25%" },
    { id: "order_date_delivered", label: "ORDER DATE", width: "10%" },
    { id: "delivered_on", label: "DELEVERED ON", width: "15%" },
    { id: "paid__delivered", label: "AMOUNT PAID", width: "10%" },
    {
      id: "shipping_address__delivered",
      label: "SHIPPING ADDRESS",
      width: "20%",
    },
    { id: "action", label: "ACTION", width: "20%" },
  ];
  const productCancelledHeaders = [
    { id: "id", label: "ID", width: "15%" },
    { id: "orders", label: "ORDERS", width: "25%" },
    { id: "order_date", label: "ORDER DATE", width: "10%" },
    { id: "cancelled_on", label: "CANCELLED ON", width: "15%" },
    { id: "paid", label: "AMOUNT PAID", width: "10%" },
    { id: "shipping_address", label: "SHIPPING ADDRESS", width: "20%" },
    { id: "action", label: "ACTION", width: "20%" },
  ];

  const requestedQuoteHeaders = [
    { id: "req_id", label: "REQUEST ID", width: "12.5%" },
    { id: "service_name", label: "SERVICE NAME", width: "12.5%" },
    { id: "request_date", label: "REQUEST DATE", width: "12.5%" },
    { id: "req_quote_text", label: "QUOTE DESCRIPTION", width: "12.5%" },
    { id: "quoted_price", label: "QUOTED PRICE", width: "12.5%" },
    { id: "service_provider", label: "SERVICE PROVIDER", width: "12.5%" },
    { id: "quote_req_status", label: "STATUS", width: "12.5%" },
    { id: "quote_action", label: "ACTION", width: "12.5%" },
  ];

  const productReturnedHeaders = [
    { id: "id", label: "ID", width: "10%" },
    { id: "orders", label: "ORDERS", width: "25%" },
    { id: "return_reason", label: "RETURN REASON", width: "10%" },
    { id: "returned_on", label: "RETURNED ON", width: "15%" },
    { id: "paid", label: "AMOUNT PAID", width: "10%" },
    { id: "shipping_address", label: "SHIPPING ADDRESS", width: "20%" },
    { id: "action", label: "ACTION", width: "10%" },
  ];

  const formatDateTime = (timestamp: number) => {
    const date = new Date(timestamp * 1000);
    return `${date.toLocaleDateString()} ${date.toLocaleTimeString()}`;
  };

  const showDetails = async (bookingId: string, type: string) => {
    try {
      setLoading(true);
      const payload = {
        booking_type: type,
        booking_id: bookingId,
      };

      const res = await _POST(
        `userProfile/users/booking`,
        payload,
        true,
        false
      );

      if (res.status && res.data) {
        setData(res.data[0]);
        if (type === "service_booking") {
          setOpenDrawer(true);
        } else {
          setOpenEventDrawer(true);
        }
        handleModalClose();
      } else {
        console.error({ message: "Failed to fetch booking details" });
      }
    } catch (error) {
      console.error({ message: "Error fetching booking details" });
    } finally {
      setLoading(false);
    }
  };

  const openOrderDrawerModal = async (id: any) => {
    setLoading(true);
    try {
      const res: any = await _GET(`supplier/customer/fetch/order/${id}`, true);
      if (res.status) {
        setUserEventData(res.data);
        setUserEventDrawerVisible(true);
        handleModalClose();
      } else {
        console.error({ message: "Failed to fetch order details" });
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const closeEventDrawer = () => {
    setUserEventDrawerVisible(false);
  };

  const closeModal = () => {
    setOpenDrawer(false);
  };

  const closeEventModal = () => {
    setOpenEventDrawer(false);
  };

  const handleTabChange = (status: any) => {
    setActiveTab(status);
    setAnchorEl(null);
  };

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const filteredUpcomingServices = filterUpcomingServices(
    upComingServices,
    searchTerm
  );

  const filteredInProgressServices = filterInProgressServices(
    inProgressServices,
    searchTerm
  );

  const filteredCompletedServices = filterCompletedServices(
    completedServices,
    searchTerm
  );

  const filteredCancelledServices = filterCancelledServices(
    cancelledServices,
    searchTerm
  );

  const filteredUpcomingEvents = filterUpcomingevents(
    upComingEvents,
    searchTerm
  );

  const filteredInProgressEvents = filterInProgressevents(
    inProgressEvents,
    searchTerm
  );

  const filteredCompletedEvents = filterCompletedevents(
    completedEvents,
    searchTerm
  );

  const filteredCancelledEvents = filterCancelledevents(
    cancelledEvents,
    searchTerm
  );

  return (
    <>
      <MobileTopDashboardNavBar />
      <div className={`customLayoutSideContainer ${Styles.orderContainer}`}>
        <DashboardNavBar />
        <div className={Styles.serviceMainContainer}>
          <div className={Styles.serviceHeader}>
            <div className={Styles.actionsContainer}>
              <h1>Orders/Bookings</h1> |{" "}
              <Button
                className={`${Styles.filterButton} ${
                  activeFilter === "Orders" && Styles.active
                }`}
                onClick={() => {
                  setActiveFilter("Orders");
                  setActiveTab("Active");
                }}
                // disabled
                // style={{ cursor: "not-allowed" }}
              >
                Orders
              </Button>
              <Button
                className={`${Styles.filterButton} ${
                  activeFilter === "Service" && Styles.active
                }`}
                onClick={() => {
                  setActiveFilter("Service");
                  getQuestions("service");
                  setActiveTab("Upcoming");
                }}
              >
                Services
              </Button>
              <Button
                className={`${Styles.filterButton} ${
                  activeFilter === "Event" && Styles.active
                }`}
                onClick={() => {
                  setActiveFilter("Event");
                  getQuestions("event");
                  setActiveTab("Upcoming");
                }}
              >
                Events
              </Button>
            </div>
            <div className={Styles.actionsContainerNext}>
              <div className={Styles.actionsContainerNext}>
                <SearchOutlined
                  className={Styles.searchIconMain}
                  onClick={showModal}
                  color="#cecbd4"
                />
                <Button
                  className={Styles.calendarButton}
                  icon={<CalendarOutlined />}
                  style={{
                    visibility:
                      activeFilter === "Service" || activeFilter === "Event"
                        ? "visible"
                        : "hidden",
                  }}
                  onClick={() =>
                    history.push({
                      pathname: `${APPLICATION_ROUTES.CALENDAR}`,
                    })
                  }
                >
                  Calendar
                </Button>
              </div>
            </div>
          </div>

          <Modal
            open={isModalVisible}
            onClose={handleModalClose}
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div className={`${Styles.modalContent} ${Styles.topPosition}`}>
              <div className={Styles.searchDiv}>
                <img src={SearchIcon} alt="SearchIcon" />
                <Input
                  ref={inputRef}
                  placeholder="Search by ID, Name, Email..."
                  value={searchTerm}
                  onChange={handleSearchInputChange}
                  className={Styles.searchInput}
                />
                <IconButton onClick={handleModalClose}>
                  <Cross />
                </IconButton>
              </div>

              <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleClose}
              >
                <MenuItem onClick={() => handleTabChange("In Progress")}>
                  Active
                </MenuItem>
                <MenuItem onClick={() => handleTabChange("Upcoming")}>
                  Upcoming
                </MenuItem>
                <MenuItem onClick={() => handleTabChange("Completed")}>
                  Completed
                </MenuItem>
                <MenuItem onClick={() => handleTabChange("Cancelled")}>
                  Cancelled
                </MenuItem>
              </Menu>

              {loading ? (
                <div className={Styles.loadingContainer}>
                  <Spin size="large" />
                </div>
              ) : serviceDataSearch.length > 0 ? (
                <div
                  className={`${Styles.resultsContainer} ${
                    serviceDataSearch.length <= 5 ? Styles.singleCard : ""
                  }`}
                >
                  <Row gutter={[16, 16]}>
                    {serviceDataSearch.map((order) => (
                      <Col xs={24} sm={12} md={8} lg={6} key={order.id}>
                        <Card
                          hoverable
                          cover={
                            <div className={Styles.cardImageWrapper}>
                              <img
                                alt="provider"
                                src={order.images[0]}
                                className={Styles.cardImage}
                              />
                              <div className={Styles.capsule}>
                                <span className={Styles.capsuleItem}>
                                  {order.status}
                                </span>
                                <span className={Styles.capsuleItem}>
                                  {order.booking_type === "service_booking"
                                    ? "Service"
                                    : order.booking_type === "event_booking"
                                    ? "Event"
                                    : "Product"}
                                </span>
                              </div>
                            </div>
                          }
                        >
                          <Card.Meta
                            title={order.name}
                            description={`Type: ${
                              order.booking_type === "service_booking"
                                ? "Service"
                                : order.booking_type === "event_booking"
                                ? "Event"
                                : "Product"
                            }
                            }`}
                          />
                          <p>Status: {order.status}</p>
                          <p>Start: {formatDateTime(order.start_time)}</p>
                          <p>End: {formatDateTime(order.end_time)}</p>
                          <p>
                            Total Amount: {order.total_amount}{" "}
                            {order.amount_paid_ccy}
                          </p>
                          {/* <Button onClick={() => showDetails(order.id)} type="primary">View Details</Button> */}
                          <Button
                            onClick={() => {
                              if (order.booking_type === "product_booking") {
                                openOrderDrawerModal(order.id);
                              } else {
                                showDetails(order.id, order.booking_type);
                              }
                            }}
                            type="primary"
                          >
                            {order.booking_type === "service_booking"
                              ? "View Service Details"
                              : order.booking_type === "event_booking"
                              ? "View Event Details"
                              : "View Product Details"}
                          </Button>
                        </Card>
                      </Col>
                    ))}
                  </Row>
                </div>
              ) : (
                <div className={Styles.noResultsContainer}>
                  <SearchOutlined className={Styles.noResultsIcon} />
                  <p className={Styles.noResultsText}>No results found</p>
                </div>
              )}
            </div>
          </Modal>

          <ProductDetailsDrawer
            visible={userEventDrawerVisible}
            onClose={closeEventDrawer}
            data={userEventData}
            mainTableData={[]}
          />

          {openDrawer1 && (
            <DetailsPage
              visible={openDrawer1}
              onCancel={closeModal}
              data={data}
            />
          )}

          {openEventDrawer && (
            <UserEventDrawer
              visible={openEventDrawer}
              onClose={closeEventModal}
              data={data}
            />
          )}
          {activeFilter === "Service" ? (
            <>
              <Tabs activeKey={activeTab} onChange={handleTabChange}>
                <TabPane tab="Cart" key="Cart" />
                <TabPane
                  tab={
                    <span className={Styles.countingSpanParent}>
                      Upcoming{" "}
                      <span className={Styles.countingSpan}>
                        {`${upComingServices.length}`}
                      </span>
                    </span>
                  }
                  key="Upcoming"
                />
                <TabPane tab="In Progress" key="In Progress" />
                <TabPane tab="Completed" key="Completed" />
                <TabPane tab="Cancelled" key="Cancelled" />
                <TabPane
                  tab={<span className={Styles.separator}>|</span>}
                  disabled
                  key="Separator"
                />
                <TabPane
                  tab={
                    <span className={Styles.countingSpanParent}>
                      Requested Quotes{" "}
                      <span className={Styles.countingSpan}>
                        {`${requestQuotes.length}`}
                      </span>
                    </span>
                  }
                  key="RequestedQuote"
                />
              </Tabs>
            </>
          ) : activeFilter === "Orders" ? (
            <>
              <Tabs activeKey={activeTab} onChange={handleTabChange}>
                <TabPane
                  tab={
                    <span className={Styles.countingSpanParent}>
                      Cart{" "}
                      <span className={Styles.countingSpan}>
                        {`${productCart?.length}`}
                      </span>
                    </span>
                  }
                  key="Cart"
                />
                <TabPane
                  tab={
                    <span className={Styles.countingSpanParent}>
                      Active{" "}
                      <span className={Styles.countingSpan}>
                        {`${mappedData?.length}`}
                      </span>
                    </span>
                  }
                  key="Active"
                />

                <TabPane
                  tab={
                    <span className={Styles.countingSpanParent}>
                      Delivered{" "}
                      <span className={Styles.countingSpan}>
                        {`${deliveredData?.length}`}
                      </span>
                    </span>
                  }
                  key="Delivered"
                />

                <TabPane
                  tab={
                    <span className={Styles.countingSpanParent}>
                      Returned{" "}
                      <span className={Styles.countingSpan}>
                        {`${returnedData.length}`}
                      </span>
                    </span>
                  }
                  key="Returned"
                />

                <TabPane
                  tab={
                    <span className={Styles.countingSpanParent}>
                      Cancelled{" "}
                      <span className={Styles.countingSpan}>
                        {`${cancelledData.length}`}
                      </span>
                    </span>
                  }
                  key="Cancelled"
                />
              </Tabs>
            </>
          ) : (
            <>
              <Tabs activeKey={activeTab} onChange={handleTabChange}>
                <TabPane tab="Cart" key="Cart" />
                <TabPane
                  tab={
                    <span className={Styles.countingSpanParent}>
                      Upcoming{" "}
                      <span className={Styles.countingSpan}>
                        {`${upComingEvents.length}`}
                      </span>
                    </span>
                  }
                  key="Upcoming"
                />
                <TabPane tab="Completed" key="Completed" />
                <TabPane tab="Cancelled" key="Cancelled" />
              </Tabs>
            </>
          )}

          {activeFilter === "Service" && (
            <>
              {activeTab === "Upcoming" && (
                <CustomTable
                  headers={headers}
                  data={filteredUpcomingServices}
                  status="Upcoming"
                  fetchData={getServices}
                  activeTab={setActiveTab}
                />
              )}
              {activeTab === "In Progress" && (
                <CustomTable
                  headers={headers}
                  data={filteredInProgressServices}
                  status="inProgress"
                  fetchData={getServices}
                  activeTab={setActiveTab}
                />
              )}
              {activeTab === "Completed" && (
                <CustomTable
                  headers={completedServiceHeaders}
                  data={filteredCompletedServices.map((service) => ({
                    ...service,
                    rating: service.is_reviewed ? (
                      <div
                        onClick={() => {
                          setServiceData(service);
                          //@ts-ignore
                          setTimeout(() => {
                            setEditService(true);
                          }, 1000);
                        }}
                      >
                        <StarFilled
                          style={{ cursor: "pointer", color: "#FFB800" }}
                        />
                        <span>{service.customer_reviewd_rating}</span>
                      </div>
                    ) : (
                      <div
                        onClick={() => {
                          openDrawer();
                          setServiceData(service);
                        }}
                        style={{ cursor: "pointer" }}
                      >
                        <StarOutlined />
                        <span>{service.customer_reviewd_rating}</span>
                      </div>
                    ),
                  }))}
                  status="Completed"
                  fetchData={getServices}
                  activeTab={setActiveTab}
                />
              )}
              {activeTab === "Cancelled" && (
                <CustomTable
                  headers={headers}
                  data={filteredCancelledServices}
                  status="Cancelled"
                  fetchData={getServices}
                  activeTab={setActiveTab}
                />
              )}
              {activeTab === "Cart" &&
                (cartItems.length > 0 ? (
                  <div className={Styles.Cartitem}>
                    {cartItems.map((item: any, index: any) => (
                      <div style={{ display: "flex", marginLeft: "15px" }}>
                        <Cardcontainer key={index} item={item} />
                      </div>
                    ))}
                  </div>
                ) : (
                  ""
                ))}
              {activeTab === "RequestedQuote" && (
                <CustomTable
                  headers={requestedQuoteHeaders}
                  data={requestQuotes}
                  status="requestQuote"
                  fetchData={fetchQuotes}
                  // activeTab={setActiveTab}
                />
              )}
            </>
          )}
          {activeFilter === "Orders" && (
            <>
              {activeTab === "Cart" && (
                <>
                  <OrderCart data={productCart} fetchCartItem={fetchCartItem} />
                </>
              )}

              {activeTab === "Active" && (
                <ProductsTable
                  headers={productHeaders}
                  data={mappedData}
                  status="Active"
                  fetchData={fetchOrders}
                  activeTab={setActiveTab}
                />
              )}
              {activeTab === "Delivered" && (
                <ProductsTable
                  headers={productDeliveredHeaders}
                  data={deliveredData}
                  status="Delivered"
                  fetchData={fetchOrders}
                  activeTab={setActiveTab}
                />
              )}
              {activeTab === "Cancelled" && (
                <ProductsTable
                  headers={productCancelledHeaders}
                  data={cancelledData}
                  status="Cancelled"
                  fetchData={fetchOrders}
                  activeTab={setActiveTab}
                />
              )}

              {activeTab === "Returned" && (
                <ProductsTable
                  headers={productReturnedHeaders}
                  data={returnedData}
                  status="Returned"
                  fetchData={fetchOrders}
                  activeTab={setActiveTab}
                />
              )}
            </>
          )}

          {activeFilter === "Event" && (
            <>
              {activeTab === "Upcoming" && (
                <EventTable
                  headers={eventHeaders}
                  data={filteredUpcomingEvents}
                  status="Upcoming"
                  fetchData={getEvents}
                  activeTab={setActiveTab}
                />
              )}
              {activeTab === "In Progress" && (
                <EventTable
                  headers={eventHeaders}
                  data={filteredInProgressEvents}
                  status="inProgress"
                  fetchData={getEvents}
                  activeTab={setActiveTab}
                />
              )}
              {activeTab === "Completed" && (
                <EventTable
                  headers={completedEventHeaders}
                  data={filteredCompletedEvents.map((event) => ({
                    ...event,
                    rating: event.is_reviewed ? (
                      <div
                        onClick={() => {
                          setEventData(event);
                          //@ts-ignore
                          setTimeout(() => {
                            setEditEvent(true);
                          }, 1000);
                        }}
                      >
                        <StarFilled
                          style={{ color: "#FFB800", cursor: "pointer" }}
                        />
                        <span>{event.customer_reviewd_rating}</span>
                      </div>
                    ) : (
                      <div
                        onClick={() => {
                          openEventReviewDrawer();
                          setEventData(event);
                        }}
                      >
                        <StarOutlined style={{ cursor: "pointer" }} />
                        <span>{event.customer_reviewd_rating}</span>
                      </div>
                    ),
                  }))}
                  status="Completed"
                  fetchData={getEvents}
                  activeTab={setActiveTab}
                />
              )}
              {activeTab === "Cancelled" && (
                <EventTable
                  headers={eventHeaders}
                  data={filteredCancelledEvents}
                  status="Cancelled"
                  fetchData={getEvents}
                  activeTab={setActiveTab}
                />
              )}
              {activeTab === "Cart" &&
                (cartEventItems.length > 0 ? (
                  <div className={Styles.Cartitem}>
                    {cartEventItems.map((item: any, index: any) => (
                      <div style={{ display: "flex", marginLeft: "15px" }}>
                        <Eventcartcontainer key={index} item={item} />
                      </div>
                    ))}
                  </div>
                ) : (
                  ""
                ))}
            </>
          )}
        </div>
      </div>
      <ReviewDrawer
        openDrawer={serviceReviewDrawer}
        closeDrawer={closeDrawer}
        questionData={questionsData}
        serviceData={serviceData}
        reloadAPI={getServices}
      />
      <EventReviewDrawer
        openDrawer={eventReviewDrawer}
        closeDrawer={closeEventReviewDrawer}
        eventData={eventData}
        questionData={questionsData}
        reloadAPI={getEvents}
      />
      <EditServiceReviewDrawer
        openDrawer={editService}
        closeDrawer={closeServiceEditDrawer}
        questionData={questionsData}
        serviceData={serviceData}
        reloadAPI={getServices}
      />
      <EditEventReviewDrawer
        openDrawer={editEvent}
        closeDrawer={closeEventEditDrawer}
        questionData={questionsData}
        serviceData={serviceData}
        eventData={eventData}
        reloadAPI={getEvents}
      />
    </>
  );
};

export default CustomerOrderPage;
